import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
// import { LoadingButton } from '@mui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Confirmation({
  description,
  open,
  handleClose,
  handleYes,
  loading = false
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle>{'Are you sure?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" color={'error'}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          // loading={loading}
          disabled={loading}
          onClick={handleYes}
          variant="contained"
          color="error">
          <span>Yes</span>
        </Button>
        <Button onClick={handleClose} variant="outlined" color={'secondary'}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
