import {
  Box,
  Button,
  Card,
  ClickAwayListener,
  Divider,
  FormLabel,
  Modal,
  Typography
} from '@mui/material';
import { useTabletOrMobile } from '../../utils/useQueryMedia';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import styles from './style.module.scss';
import svg from '../../assets/svg/cloud.svg';
import plus from '../../assets/svg/plus.svg';
import CardShopping from '../card-items/card-items';
import {
  set_add_product,
  set_add_shop,
  set_close_modal,
  set_show_product
} from '../../redux/modal';
import SelectCustom from '../custom-select/select';
import {
  useCreate,
  useGetAll,
  useGetIdLogin,
  useUpdateAll
} from '../../utils/functions';
import customAxios from '../../axios/custom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '../toast/toast';
import { selectProducts } from '../../redux/product';
import { getOrder } from '../../api/shops';
import {
  generatePaymentIntent,
  getStatusChange,
  updateUser,
  userLogin,
  fetchTransactions,
  getConnectAccountDetails
} from '../../api/product';
import BankIcon from './bankIcon.png';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 50 + '%',
  backgroundColor: '#f2f2f2',
  boxShadow: 24,
  padding: '2rem 1rem',
  maxHeight: '85vh',
  overflowY: 'auto',
  borderRadius: '25px'
};
const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 90 + '%',
  backgroundColor: '#f2f2f2',
  boxShadow: 24,
  padding: '2rem 1rem',
  maxHeight: '85vh',
  overflowY: 'auto',
  borderRadius: '25px'
};

export function GetOrderDetails({ id }) {
  const [open, setOpen] = useState(false);
  const [img, setImg] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => dispatch(set_close_modal());
  const dispatch = useDispatch();
  const { get_order } = useSelector(state => state.modalReducer);

  useEffect(() => {
    setOpen(get_order);
  }, [get_order]);

  const getId = async () => {
    try {
      const response = await getOrder(id);

      if (!response) {
        throw new Error('Failed to get user');
      }
      return response;
    } catch (error) {
      // Handle errors appropriately
      console.error('Error fetching user:', error);
      throw error;
    }
  };

  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: ['get-one-order-cc'],
    queryFn: getId,
    refetchOnWindowFocus: false,
    enabled: true
  });

  const isMobile = useTabletOrMobile();

  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div style={isMobile ? styleMobile : style}>
          <div className={styles.modal_data}>
            {data && !isLoading && (
              <div className={styles.container}>
                <div className={styles.modal_title}>
                  <p>DETAILS SUR LA COMMANDE</p>
                </div>
                {isMobile && (
                  <div className={styles.card_mobile}>
                    <p>Prix : {data.price} EUR</p>
                    <p> Email: {data.customer_email}</p>
                    <p>Nom client : {data.name}</p>
                    <p>Date : {data.dateOffre}</p>
                  </div>
                )}

                {data.products.map((x,i) => {
                  return (
                    <div className={styles.boxCard} key={i}>
                      <div
                        className={
                          styles.card_main_empty + ' ' + styles.background_white
                        }>
                        <div className={styles.img_text}>
                          <div className={styles.img}>
                            <img src={x.photos[0]} alt="clock" />
                          </div>
                          <p>
                            {x.title} - X {x.quantity}
                          </p>
                        </div>
                        <p>{x.price}€</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
/**** PROD */
export function ModalModifyProduct({ prod, closeModal }) {
  const [open, setOpen] = useState(false);
  const [img, setImg] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    dispatch(set_close_modal());
    closeModal();
  };
  const dispatch = useDispatch();
  const { modify_shop } = useSelector(state => state.modalReducer);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting }
  } = useForm();
  const creationMutation = useUpdateAll('product');
  const onSubmit = data => {
    const arr = { ...data, photos: photos };

    if (photos.length > 0) {
      creationMutation.mutate(
        { from: 'product', id: prod._id, data: arr },
        {
          onSuccess: (data, error, variables, context) => {
            handleClose();
          }
        }
      );
    } else {
      showErrorToast('Vous devez ajouter des images !');
    }
  };

  useEffect(() => {
    setOpen(modify_shop);
  }, [modify_shop]);

  const isMobile = useTabletOrMobile();
  const [uploadProgress, setUploadProgress] = useState(0);

  async function uploadImagesProducts(data) {
    const res = await customAxios.post(
      '/api/v1/file/create-images-product',
      data,
      {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        }
      }
    );
    return res.data;
  }

  const UpdateMultiImage = useMutation({
    mutationFn: uploadImagesProducts,

    onError: error => {
      showErrorToast("Erreur lors de l'ajout des images ! ");
    },
    onSuccess: data => {
      console.log(data);
      data.map(x => {
        return setPhotos(old => [...old, x]);
      });
      setUploadProgress(0);
    }
  });

  const [photos, setPhotos] = useState([]);

  function handleData(e) {
    const selectedFiles = e.target.files;
    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      formData.append('files', file);
    }
    UpdateMultiImage.mutate(formData);
  }

  const [prodItem, setProdItem] = useState({
    confirmation: '',
    description: '',
    name: '',
    photos: prod?.photos,
    price: '',
    title: ''
  });
  useEffect(() => {
    if (prod) {
      setValue('title', prod.title);
      setValue('name', prod.name);
      setValue('price', prod.price);
      setValue('confirmation', prod.confirmation);
      setValue('description', prod.description);
      setPhotos(prod.photos);
    }
  }, [prod]);

  function deleteImage(index) {
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div style={isMobile ? styleMobile : style}>
          <div className={styles.modal_data}>
            <div className={styles.container}>
              <div className={styles.modal_title}>
                <p>MODIFIER VOTRE PRODUITS</p>
              </div>
              <div className={styles.modal_form}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={styles.form_text}>
                  <div className={styles.box}>
                    <label>Titre Pack :</label>
                    <br></br>
                    <Controller
                      name="title"
                      control={control}
                      render={({ field }) => (
                        <input
                          defaultValue={prodItem?.title}
                          {...field}
                          className={styles.input_text}
                          placeholder="Pack Title"
                          required
                        />
                      )}
                    />
                  </div>

                  <div className={styles.box}>
                    <label>Nom Pack :</label>
                    <br></br>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <input
                          defaultValue={prodItem?.name}
                          {...field}
                          className={styles.input_text}
                          placeholder="Pack Name"
                          required
                        />
                      )}
                    />
                  </div>
                  <div className={styles.flex}>
                    <div className={styles.box_w50}>
                      <label>Prix :</label>
                      <br></br>
                      <Controller
                        name="price"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="number"
                            defaultValue={prodItem?.price}
                            {...field}
                            className={styles.input_text}
                            placeholder="Pack Price"
                            required
                          />
                        )}
                      />
                    </div>

                    <div className={styles.box_w50}>
                      <label>Besoin d'une confirmation</label>
                      <br></br>
                      <Controller
                        name="confirmation"
                        control={control}
                        render={({ field }) => (
                          <select
                            {...field}
                            defaultValue={prodItem?.confirmation}
                            className={styles.input_text}
                            placeholder="Pack Confirmation"
                            required>
                            <option value="">Sélectionner</option>
                            <option value="yes">Oui</option>
                            <option value="no">Non</option>
                          </select>
                        )}
                      />
                    </div>
                  </div>

                  <div className={styles.box}>
                    <label>Pack Description</label>
                    <br></br>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <textarea
                          {...field}
                          defaultValue={prodItem?.description}
                          className={styles.input_text}
                          placeholder="Pack Description"
                          required
                        />
                      )}
                    />
                  </div>

                  <div className={styles.box}>
                    <label>Photos</label>
                    <br></br>
                    {photos?.length > 0 ? (
                      <div className={styles.input_file_filled}>
                        <div className={styles.many_files}>
                          {photos?.map((x, index) => {
                            return (
                              <div className={styles.div_many} key={index}>
                                <div
                                  className={styles.delete_icon}
                                  onClick={e => deleteImage(index)}>
                                  <i className="fa-solid fa-trash"></i>
                                </div>
                                <img src={x} className={styles.img_many} />
                              </div>
                            );
                          })}
                          <div className={styles.plus}>
                            <i className="fa-solid fa-plus"></i>
                            <input
                              type="file"
                              className={styles.hidden}
                              onChange={handleData}
                              multiple={true}
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.input_file}>
                        <div className={styles.cloud}>
                          <div className={styles.group}>
                            <img src={svg} alt="svg" />

                            <p>Ajouter des photos </p>
                          </div>
                        </div>
                        <input
                          type="file"
                          className={styles.hidden}
                          onChange={handleData}
                          multiple={true}
                          accept="image/*"
                        />
                      </div>
                    )}
                  </div>

                  <button disabled={isSubmitting} type="submit">
                    Valider
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
/**** SHOP */
export function ModalModifyShop({ prod, closeModal }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    dispatch(set_close_modal());
    closeModal();
  };
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, isSubmitting }
  } = useForm();

  const { modify_product } = useSelector(state => state.modalReducer);
  useEffect(() => {
    setOpen(modify_product);
  }, [modify_product]);

  const isMobile = useTabletOrMobile();
  const [opens, setOpens] = useState(false);

  const handleClick = () => {
    setOpens(prev => !prev);
  };

  const handleClickAway = () => {
    setOpens(false);
  };

  const creationMutation = useUpdateAll('shop');
  const { data, isLoading, isError, refetch } = useGetAll('product');
  const onSubmit = data => {
    console.log(photos);
    if (photos.length > 0) {
      const arr = { ...data, photo: photos };
      creationMutation.mutate(
        { from: 'shop', id: prod._id, data: arr },
        {
          onSuccess: (data, error, variables, context) => {
            handleClose();
          }
        }
      );
    } else {
      showErrorToast('Vous devez ajouter des images !');
    }
  };

  const [uploadProgress, setUploadProgress] = useState(0);
  // useEffect(() => {
  //     console.log(prod);
  // }, [prod])
  const [img, setImg] = useState(prod && prod ? prod.photos : '');
  async function uploadImagesProducts(data) {
    const res = await customAxios.post(
      '/api/v1/file/create-images-product',
      data,
      {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        }
      }
    );
    return res.data;
  }

  const UpdateMultiImage = useMutation({
    mutationFn: uploadImagesProducts,

    onError: error => {
      showErrorToast("Erreur lors de l'ajout des images ! ");
    },
    onSuccess: data => {
      setPhotos(data[0]);
      setUploadProgress(0);
    }
  });

  const [array, setArray] = useState(
    prod && prod ? prod.products : [{ title: 'Pas de produit' }]
  );
  const [item, setItem] = useState([]);

  function handleSelect(all) {
    if (!array.some(item => item.title === all?.title)) {
      setArray(old => {
        if (old.length === 1 && old[0].title === 'Pas de produits') {
          return [all];
        } else {
          return [...old, all];
        }
      });
    } else {
      setArray(old => old.filter(x => x.title !== all?.title));

      if (array.length === 1 && array[0].title !== 'Pas de produits') {
        setArray([{ title: 'Pas de produits' }]);
      }
    }
  }

  function handleData(e) {
    const selectedFiles = e.target.files[0];
    const formData = new FormData();

    formData.append('files', selectedFiles);

    UpdateMultiImage.mutate(formData);
  }

  useEffect(() => {
    if (array && array.length > 0) setValue('products', array);
  }, [array]);
  const [photos, setPhotos] = useState();
  useEffect(() => {
    if (prod) {
      setValue('title', prod.title);
      setValue('email', prod.email);
      setValue('whatsapp', prod.whatsapp);
      setValue('confirmation', prod.confirmation);

      setPhotos(prod.photo);
    }
    console.log(prod);
  }, [prod]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        {prod && (
          <div style={isMobile ? styleMobile : style}>
            <div className={styles.modal_data}>
              <div className={styles.container}>
                <div className={styles.modal_title}>
                  <p>MODIFIER VOTRE BOUTIQUE</p>
                </div>
                <div className={styles.modal_form}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={styles.form_text}>
                    <div className={styles.box}>
                      <label>Titre Shop :</label>
                      <br></br>
                      <Controller
                        name="title"
                        control={control}
                        rules={{
                          required: 'Titre Shop est requis'
                        }}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              defaultValue={prod?.title}
                              className={styles.input_text}
                              placeholder="Titre Shop"
                            />
                            {errors.title && (
                              <p className={styles.error}>
                                {errors.title.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>

                    <div className={styles.box}>
                      <label>Courrier de notification :</label>
                      <br></br>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: 'Notification mail est requis'
                        }}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              defaultValue={prod?.email}
                              className={styles.input_text}
                              placeholder="Notification mail"
                            />
                            {errors.email && (
                              <p className={styles.error}>
                                {errors.email.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>

                    {/* <div className={styles.box}>
											<label>
												Notification WhatsApp :
											</label>
											<br></br>
											<Controller
												name='whatsapp'
												control={control}
												rules={{
													required:
														'Notification WhatsApp est requis',
													pattern: {
														value: /^\+[0-9]+$/,
														message:
															"Le numéro de téléphone doit commencer par un '+' et ne contenir que des chiffres.",
													},
												}}
												render={({ field }) => (
													<>
														<input
															{...field}
															defaultValue={
																prod?.whatsapp
															}
															className={
																styles.input_text
															}
															placeholder='Notification WhatsApp'
														/>
														{errors.whatsapp && (
															<p
																className={
																	styles.error
																}
															>
																{
																	errors
																		.whatsapp
																		.message
																}
															</p>
														)}
													</>
												)}
											/>
										</div> */}

                    <div className={styles.box}>
                      <label>Besoin d'une confirmation</label>
                      <br></br>
                      <Controller
                        name="confirmation"
                        control={control}
                        rules={{
                          required: 'Confirmation est requis'
                        }}
                        render={({ field }) => (
                          <>
                            <select
                              {...field}
                              defaultValue={prod?.confirmation}
                              className={styles.input_text}
                              placeholder="Sélectionner">
                              <option value="">Sélectionner</option>
                              <option value="yes">Oui</option>
                              <option value="no">Non</option>
                            </select>
                            {errors.confirmation && (
                              <p className={styles.error}>
                                {errors.confirmation.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>

                    <div className={styles.box}>
                      <label>Produit dans cette boutique :</label>
                      <br></br>
                      {/* ... (Your existing code for products field) */}
                      {open && (
                        <Controller
                          name="products"
                          control={control}
                          rules={{
                            required: 'Products est requis'
                          }}
                          render={({ field }) => (
                            <>
                              <div
                                {...field}
                                className={styles.input_text}
                                onClick={handleClick}
                                placeholder="Produits">
                                {array &&
                                  array.map((x, index) => (
                                    <p
                                      key={index}
                                      className={styles.input_text_div}>
                                      {x.title}
                                    </p>
                                  ))}
                              </div>
                              {opens ? (
                                <ClickAwayListener
                                  mouseEvent="onMouseDown"
                                  touchEvent="onTouchStart"
                                  onClickAway={handleClickAway}>
                                  <div className={styles.border_select}>
                                    {data &&
                                      !isLoading &&
                                      data.map((x,i) => {
                                        return (
                                          <SelectCustom
                                          key={i}
                                            text={x.title}
                                            item={x}
                                            img={x.photos[0]}
                                            onChange={() => {
                                              handleSelect(x);
                                            }}
                                            isChecked={array.find(
                                              y => y.title == x.title
                                            )}></SelectCustom>
                                        );
                                      })}
                                  </div>
                                </ClickAwayListener>
                              ) : null}
                              {errors.products && (
                                <p className={styles.error}>
                                  {errors.products.message}
                                </p>
                              )}
                            </>
                          )}
                        />
                      )}
                    </div>

                    <div className={styles.box}>
                      <label>Photo :</label>
                      <br></br>

                      {photos && photos.length > 0 ? (
                        <div className={styles.input_file_filled}>
                          <div className={styles.cloud_op}>
                            <div className={styles.group}>
                              <img src={svg} alt="svg" />
                              <p>modifier des photos </p>
                            </div>
                            <img
                              src={photos}
                              alt="img"
                              className={styles.imgFile}
                            />
                            <input
                              type="file"
                              className={styles.hidden}
                              onChange={handleData}
                              placeholder="Pack Photos"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className={styles.input_file}>
                          <div className={styles.cloud}>
                            <div className={styles.group}>
                              <img src={svg} alt="svg" />
                              <p>Ajouter des photos </p>
                            </div>
                            <input
                              type="file"
                              className={styles.hidden}
                              onChange={handleData}
                              placeholder="Pack Photos"
                            />
                          </div>
                        </div>
                      )}
                      {errors.photo && (
                        <p className={styles.error}>{errors.photo.message}</p>
                      )}
                    </div>

                    <button disabled={isSubmitting} type="submit">
                      Valider
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
export function ModalAddProduct() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => dispatch(set_close_modal());
  const dispatch = useDispatch();
  const { add_product } = useSelector(state => state.modalReducer);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, isSubmitting }
  } = useForm();

  useEffect(() => {
    setOpen(add_product);
  }, [add_product]);

  const isMobile = useTabletOrMobile();
  const [opens, setOpens] = useState(false);

  const handleClick = () => {
    setOpens(prev => !prev);
  };

  const handleClickAway = () => {
    setOpens(false);
  };

  const creationMutation = useCreate('shop');
  const { data, isLoading, isError, refetch } = useGetAll('product');
  const onSubmit = data => {
    console.log(img);
    if (img.length > 0) {
      const arr = { ...data, photo: img };
      creationMutation.mutate(
        { from: 'shop', data: arr },
        {
          onSuccess: (data, error, variables, context) => {
            handleClose();
          }
        }
      );
    } else {
      showErrorToast('Vous devez ajouter des images !');
    }
  };

  const [uploadProgress, setUploadProgress] = useState(0);

  const [img, setImg] = useState('');
  async function uploadImagesProducts(data) {
    const res = await customAxios.post(
      '/api/v1/file/create-images-product',
      data,
      {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        }
      }
    );
    return res.data;
  }

  const UpdateMultiImage = useMutation({
    mutationFn: uploadImagesProducts,

    onError: error => {
      showErrorToast("Erreur lors de l'ajout des images ! ");
    },
    onSuccess: data => {
      setImg(data[0]);
      setUploadProgress(0);
    }
  });

  const [array, setArray] = useState([{ title: 'Pas de produits' }]);
  const [item, setItem] = useState([]);

  function handleSelect(all) {
    if (!array.some(item => item.title === all?.title)) {
      setArray(old => {
        if (old.length === 1 && old[0].title === 'Pas de produits') {
          return [all];
        } else {
          return [...old, all];
        }
      });
    } else {
      setArray(old => old.filter(x => x.title !== all?.title));

      if (array.length === 1 && array[0].title !== 'Pas de produits') {
        setArray([{ title: 'Pas de produits' }]);
      }
    }
  }

  function handleData(e) {
    const selectedFiles = e.target.files[0];
    const formData = new FormData();

    formData.append('files', selectedFiles);

    UpdateMultiImage.mutate(formData);
  }

  useEffect(() => {
    if (array && array.length > 0) setValue('products', array);
  }, [array]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div style={isMobile ? styleMobile : style}>
          <div className={styles.modal_data}>
            <div className={styles.container}>
              <div className={styles.modal_title}>
                <p>AJOUTEZ VOS BOUTIQUES</p>
              </div>
              <div className={styles.modal_form}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={styles.form_text}>
                  <div className={styles.box}>
                    <label>Titre Shop :</label>
                    <br></br>
                    <Controller
                      name="title"
                      control={control}
                      rules={{
                        required: 'Titre Shop est requis'
                      }}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            className={styles.input_text}
                            placeholder="Titre Shop"
                          />
                          {errors.title && (
                            <p className={styles.error}>
                              {errors.title.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>

                  <div className={styles.box}>
                    <label>Courrier de notification :</label>
                    <br></br>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: 'Notification mail est requis'
                      }}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            className={styles.input_text}
                            placeholder="Notification mail"
                          />
                          {errors.email && (
                            <p className={styles.error}>
                              {errors.email.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>

                  {/* <div className={styles.box}>
                                        <label>Notification WhatsApp :</label>
                                        <br></br>
                                        <Controller
                                            name="whatsapp"
                                            control={control}
                                            rules={{ required: "Notification WhatsApp est requis" }}
                                            render={({ field }) => (
                                                <>
                                                    <input {...field} className={styles.input_text} placeholder="Notification WhatsApp" />
                                                    {errors.whatsapp && <p className={styles.error}>{errors.whatsapp.message}</p>}
                                                </>
                                            )}
                                        />
                                    </div> */}

                  <div className={styles.box}>
                    <label>Besoin d'une confirmation</label>
                    <br></br>
                    <Controller
                      name="confirmation"
                      control={control}
                      rules={{
                        required: 'Confirmation est requis'
                      }}
                      render={({ field }) => (
                        <>
                          <select
                            {...field}
                            className={styles.input_text}
                            placeholder="Sélectionner">
                            <option value="">Sélectionner</option>
                            <option value="yes">Oui</option>
                            <option value="no">Non</option>
                          </select>
                          {errors.confirmation && (
                            <p className={styles.error}>
                              {errors.confirmation.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>

                  <div className={styles.box}>
                    <label>Produits :</label>
                    <br></br>
                    {/* ... (Your existing code for products field) */}
                    {open && (
                      <Controller
                        name="products"
                        control={control}
                        rules={{
                          required: 'Products est requis'
                        }}
                        render={({ field }) => (
                          <>
                            <div
                              {...field}
                              className={styles.input_text}
                              onClick={handleClick}
                              placeholder="Produits">
                              {array.map((x, index) => (
                                <p
                                  key={index}
                                  className={styles.input_text_div}>
                                  {x.title}
                                </p>
                              ))}
                            </div>
                            {opens ? (
                              <ClickAwayListener
                                mouseEvent="onMouseDown"
                                touchEvent="onTouchStart"
                                onClickAway={handleClickAway}>
                                <div className={styles.border_select}>
                                  {data &&
                                    !isLoading &&
                                    data.map((x,i) => {
                                      return (
                                        <SelectCustom
                                        key={i}
                                          text={x.title}
                                          item={x}
                                          img={x.photos[0]}
                                          onChange={() => {
                                            handleSelect(x);
                                          }}
                                          isChecked={array.find(
                                            y => y.title == x.title
                                          )}></SelectCustom>
                                      );
                                    })}
                                </div>
                              </ClickAwayListener>
                            ) : null}
                            {errors.products && (
                              <p className={styles.error}>
                                {errors.products.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    )}
                  </div>

                  <div className={styles.box}>
                    <label>Photo :</label>
                    <br></br>
                    <div className={styles.input_file}>
                      <div className={styles.cloud}>
                        <div className={styles.group}>
                          <img src={svg} alt="svg" />
                          <p>Ajouter des photos </p>
                        </div>
                      </div>
                      {img.length > 0 ? (
                        <img src={img} alt="img" className={styles.imgFile} />
                      ) : (
                        <input
                          type="file"
                          className={styles.hidden}
                          onChange={handleData}
                          placeholder="Pack Photos"
                        />
                      )}
                      {errors.photo && (
                        <p className={styles.error}>{errors.photo.message}</p>
                      )}
                    </div>
                  </div>

                  <button disabled={isSubmitting} type="submit">
                    Valider
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export function ModalChoseProduct() {
  const [open, setOpen] = useState(false);
  const [img, setImg] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => dispatch(set_close_modal());
  const dispatch = useDispatch();
  const { add_shop } = useSelector(state => state.modalReducer);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = useForm();
  const creationMutation = useCreate('product');
  const onSubmit = data => {
    if (img.length > 0) {
      const arr = { ...data, photos: img };
      creationMutation.mutate(
        { from: 'product', data: arr },
        {
          onSuccess: (data, error, variables, context) => {
            handleClose();
          }
        }
      );
    } else {
      showErrorToast('Vous devez ajouter des images !');
    }
  };

  useEffect(() => {
    setOpen(add_shop);
  }, [add_shop]);

  const isMobile = useTabletOrMobile();
  const [uploadProgress, setUploadProgress] = useState(0);
  async function uploadImagesProducts(data) {
    const res = await customAxios.post(
      '/api/v1/file/create-images-product',
      data,
      {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        }
      }
    );
    return res.data;
  }

  const UpdateMultiImage = useMutation({
    mutationFn: uploadImagesProducts,

    onError: error => {
      showErrorToast("Erreur lors de l'ajout des images ! ");
    },
    onSuccess: data => {
      data.map(x => {
        return setImg(old => [...old, x]);
      });
      setUploadProgress(0);
    }
  });

  // useEffect(() => {
  //     console.log(img);
  // }, [img])

  function handleData(e) {
    const selectedFiles = e.target.files;
    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      formData.append('files', file);
    }
    UpdateMultiImage.mutate(formData);
  }

  function deleteImage(index) {
    const updatedPhotos = [...img];
    updatedPhotos.splice(index, 1);
    setImg(updatedPhotos);
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div style={isMobile ? styleMobile : style}>
          <div className={styles.modal_data}>
            <div className={styles.container}>
              <div className={styles.modal_title}>
                <p>AJOUTEZ VOS PRODUITS SUPPLÉMENTAIRES</p>
              </div>
              <div className={styles.modal_form}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={styles.form_text}>
                  <div className={styles.box}>
                    <label>Titre Pack :</label>
                    <br></br>
                    <Controller
                      name="title"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={styles.input_text}
                          placeholder="Pack Title"
                          required
                        />
                      )}
                    />
                  </div>

                  <div className={styles.box}>
                    <label>Nom Pack :</label>
                    <br></br>
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={styles.input_text}
                          placeholder="Pack Name"
                          required
                        />
                      )}
                    />
                  </div>
                  <div className={styles.flex}>
                    <div className={styles.box_w50}>
                      <label>Prix :</label>
                      <br></br>
                      <Controller
                        name="price"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <input
                            type="number"
                            {...field}
                            className={styles.input_text}
                            placeholder="Pack Price"
                            required
                          />
                        )}
                      />
                    </div>

                    <div className={styles.box_w50}>
                      <label>Besoin d'une confirmation</label>
                      <br></br>
                      <Controller
                        name="confirmation"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <select
                            {...field}
                            className={styles.input_text}
                            placeholder="Pack Confirmation"
                            required>
                            <option value="">Sélectionner</option>
                            <option value="yes">Oui</option>
                            <option value="no">Non</option>
                          </select>
                        )}
                      />
                    </div>
                  </div>

                  <div className={styles.box}>
                    <label>Pack Description</label>
                    <br></br>
                    <Controller
                      name="description"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <textarea
                          {...field}
                          className={styles.input_text}
                          placeholder="Pack Description"
                          required
                        />
                      )}
                    />
                  </div>

                  <div className={styles.box}>
                    <label>Photos</label>
                    <br></br>
                    {img?.length > 0 ? (
                      <div className={styles.input_file_filled}>
                        <div className={styles.many_files}>
                          {img?.map((x, index) => {
                            return (
                              <div className={styles.div_many} key={index}>
                                <div
                                  className={styles.delete_icon}
                                  onClick={e => deleteImage(index)}>
                                  <i className="fa-solid fa-trash"></i>
                                </div>
                                <img src={x} className={styles.img_many} />
                              </div>
                            );
                          })}
                          <div className={styles.plus}>
                            <i className="fa-solid fa-plus"></i>
                            <input
                              type="file"
                              className={styles.hidden}
                              onChange={handleData}
                              multiple={true}
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.input_file}>
                        <div className={styles.cloud}>
                          <div className={styles.group}>
                            <img src={svg} alt="svg" />

                            <p>Ajouter des photos </p>
                          </div>
                        </div>
                        <input
                          type="file"
                          className={styles.hidden}
                          onChange={handleData}
                          multiple={true}
                          accept="image/*"
                        />
                      </div>
                    )}
                  </div>

                  <button disabled={isSubmitting} type="submit">
                    Valider
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export function ModalShowProduct() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => dispatch(set_close_modal());
  const dispatch = useDispatch();
  const { show_product } = useSelector(state => state.modalReducer);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = useForm();

  const onSubmit = data => {
    console.log(data);
    // You can handle form submission logic here
  };

  useEffect(() => {
    setOpen(show_product);
  }, [show_product]);

  const products = useSelector(selectProducts);

  const isMobile = useTabletOrMobile();

  function handleShowProd() {
    dispatch(set_add_shop());
  }

  // useEffect(() => {
  //     console.log(products);
  // }, [products])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div style={isMobile ? styleMobile : style}>
          <div className={styles.modal_data}>
            <div className={styles.container}>
              <div className={styles.modal_flex}>
                <p>PRODUITS EXTRA</p>
                <button className={styles.text_btn} onClick={handleShowProd}>
                  {' '}
                  <img src={plus} alt="plus" /> Ajouter un produits
                </button>
              </div>
              <div className={styles.prod_select}>
                <p>Produits prédéfinis</p>
                {products?.map((x, index) => {
                  return (
                    <CardShopping
                      title={x.title}
                      img={x.photos[0]}
                      key={x._id}></CardShopping>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ModalChoseProduct></ModalChoseProduct>
    </div>
  );
}

export function SettingsUsers() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(set_close_modal());
  const { settings } = useSelector(state => state.modalReducer);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting }
  } = useForm();
  const [vat, setVat] = useState();
  const getId = async () => {
    try {
      const token = localStorage.getItem('token_verif');
      const response = await userLogin({ token });
      if (!response) {
        throw new Error('Failed to get user');
      }
      return response;
    } catch (error) {
      // Handle errors appropriately
      console.error('Error fetching user:', error);
      throw error;
    }
  };

  const { data, isLoading, refetch, isError } = useQuery({
    queryKey: ['get-one-vvcccccccc'],
    queryFn: getId,
    refetchOnWindowFocus: false,
    enabled: true,
    retry: true
  });

  const UpdateUser = useMutation({
    mutationFn: updateUser,

    onError: error => {
      showErrorToast('Erreur lors la modification !');
    },
    onSuccess: data => {
      dispatch(set_close_modal());
      showSuccessToast('Modification faite avec succés !');
    }
  });

  const onSsubmit = data => {
    console.log({ ...data, vat }, 'ddddddddddd');
    UpdateUser.mutate({ ...data, vat });
  };

  useEffect(() => {
    refetch();
    // console.log("ddddddd");
  }, [settings]);

  useEffect(() => {
    if (data && !isLoading) {
      setVat(data.vat);
      setValue('vat', data.vat);
      if (data.vat == 'true')
        setValue('vatTaxPercentage', data.vatTaxPercentage);
    }
    // console.log(data);
  }, [data]);

  useEffect(() => {
    setOpen(settings);
  }, [settings]);

  const isMobile = useTabletOrMobile();

  const navigate = useNavigate();

  const UpdateMultiImage = useMutation({
    mutationFn: generatePaymentIntent,

    onError: error => {
      console.log(error);
    },
    onSuccess: data => {
      window.location.href = data.link_pay;
    }
  });

  function generateConif() {
    UpdateMultiImage.mutate();
  }
  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    p: 2.5,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(15.3px)',
    WebkitBackdropFilter: 'blur(15.3px)',
    border: '1px solid rgba(255, 255, 255, 0.08)',
    outline: 'none'
  };

  const buttonStyle = {
    borderRadius: 2.5,
    backgroundImage: 'linear-gradient(45deg, #5971fe 55%, transparent)',
    backgroundColor: 'palevioletred'
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            ...boxStyle
          }}>
          <Typography variant="h4">Paramètres</Typography>
          <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h6">Stripe</Typography>
            <Button
              type="button"
              variant="contained"
              sx={buttonStyle}
              onClick={generateConif}>
              Configurer
            </Button>
          </Box>

          <Divider sx={{ my: 2 }} />
          {data && !isLoading && (
            <Box
              component={'form'}
              sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                '& select': {
                  width: '100%',
                  borderRadius: 2.5,
                  p: 1.5,
                  border: '1px solid lightgray'
                }
              }}
              onSubmit={handleSubmit(onSsubmit)}>
              <FormLabel>Frais TVA</FormLabel>
              <select
                onChange={e => setVat(e.target.value)}
                className={styles.input_text}
                placeholder="Pack Confirmation"
                required>
                <option
                  value={data.vatTaxPercentage ? data.vatTaxPercentage : ''}>
                  {data.vatTaxPercentage
                    ? data.vatTaxPercentage
                    : 'Sélectionner'}
                </option>
                <option value={'true'}>Oui</option>
                <option value={'false'}>Non</option>
              </select>
              {vat === 'true' && (
                <Box
                  sx={{
                    '& input': {
                      width: '100%',
                      borderRadius: 2.5,
                      p: 1.5,
                      border: '1px solid lightgray'
                    }
                  }}>
                  <FormLabel>Tax Vat</FormLabel>
                  <br />
                  <Controller
                    name="vatTaxPercentage"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="number"
                        name="vatTaxPercentage"
                        placeholder="Taux en %"
                        required
                      />
                    )}
                  />
                </Box>
              )}
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                sx={{ ...buttonStyle, mt: 1 }}>
                Valider
              </Button>
            </Box>
          )}
        </Box>
        {/* <div style={isMobile ? styleMobile : style}>
          <div className={styles.modal_data}>
            <div className={styles.container}>
              <div className={styles.modal_flex}>
                <p>Paramètres</p>
              </div>
              {data && !isLoading && (
                <div className={styles.modal_form}>
                  <form
                    onSubmit={handleSubmit(onSsubmit)}
                    className={styles.form_text}>
                    <div className={styles.box}>
                      <label> stripe :</label>
                      <br></br>
                      <a onClick={generateConif}>Configurer</a>
                    </div>
                    <div className={styles.box}>
                      <label>Frais TVA</label>
                      <br />
                      <select
                        onChange={e => setVat(e.target.value)}
                        className={styles.input_text}
                        placeholder="Pack Confirmation"
                        required>
                        <option
                          value={
                            data.vatTaxPercentage ? data.vatTaxPercentage : ''
                          }>
                          {data.vatTaxPercentage
                            ? data.vatTaxPercentage
                            : 'Sélectionner'}
                        </option>
                        <option value={'true'}>Oui</option>
                        <option value={'false'}>Non</option>
                      </select>
                    </div>
                    {vat === 'true' && (
                      <div className={styles.box}>
                        <label>Tax Vat</label>
                        <br />
                        <Controller
                          name="vatTaxPercentage"
                          control={control}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="number"
                              className={styles.input_text}
                              name="vatTaxPercentage"
                              placeholder="Taux en %"
                              required
                            />
                          )}
                        />
                      </div>
                    )}
                    <button type="submit" disabled={isSubmitting}>
                      Valider
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div> */}
      </Modal>
    </div>
  );
}

export function Transactions({ open, setOpen }) {
  const dispatch = useDispatch();
  const { data: userData } = useGetIdLogin();

  const handleClose = () => setOpen(false);
  // const { settings } = useSelector((state) => state.modalReducer)
  const [transactions, setTransactions] = useState([]);
  const [accountDetails, setAccountDetails] = useState(null);

  useEffect(() => {
    (async _ => {
      if (userData?.back_acc) {
        const data = await fetchTransactions(userData?.back_acc);
        setTransactions(data);

        if (accountDetails == null) {
          const data = await getConnectAccountDetails(userData?.back_acc);
          setAccountDetails(data[0]);
        }
      }
    })();
  }, [userData]);

  const isMobile = useTabletOrMobile();

  const navigate = useNavigate();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%' : 800,
    backgroundColor: '#F7F7F7',
    boxShadow: 24,
    p: 4,
    borderRadius: 20,
    maxHeight: '85vh',
    overflowY: 'auto',
    padding: 50
  };

  const styleMobile = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    backgroundColor: '#F7F7F7',
    boxShadow: 24,
    p: 4,
    borderRadius: 20,
    padding: 50
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    border: '1px solid #ddd',
    marginBottom: '1px'
  };

  const thStyle = {
    padding: '8px 16px',
    border: '1px solid #ddd',
    textAlign: 'left',
    backgroundColor: '##FFFFFF',
    color: '#333',
    borderRight: 'hidden'
  };

  const tdStyle = {
    padding: '8px 16px',
    border: '1px solid #ddd',
    textAlign: 'left',
    fontFamily: 'Poppins',
    borderRight: 'hidden'
  };

  const evenRowStyle = {
    backgroundColor: '##FFFFFF'
  };

  const firstColumnStyle = {
    width: '10%'
  };

  const bankIcon = {
    marginRight: 20,
    fontSize: 40
  };

  const completedTransactions = transactions.filter(
    transaction => transaction.status === 'available'
  );
  const totalAmount = completedTransactions.reduce(
    (total, transaction) => total + transaction.source.amount / 100,
    0
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div style={isMobile ? styleMobile : style}>
          <div className={styles.modal_data} style={{ borderRadius: '20px' }}>
            <div className={styles.container}>
              <div className={styles.modal_flex}>
                <p>Détails du compte</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #ccc',
                  borderRadius: 8,
                  padding: 20,
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                }}>
                <div style={bankIcon}>
                  <img style={{ width: 50 }} src={BankIcon} alt="Bank Icon" />
                </div>
                <div>
                  <h4 style={{ margin: '0 0 10px 0' }}>
                    {accountDetails?.bank_name}
                  </h4>
                  <p
                    style={{
                      margin: '5px 0',
                      fontSize: 'small',
                      fontWeight: 'bold'
                    }}>
                    Numéro de compte: XXXX XXXX XXXX {accountDetails?.last4}
                  </p>
                  <p
                    style={{
                      margin: '5px 0',
                      fontSize: 'small',
                      fontWeight: 'bold'
                    }}>
                    Numéro d'acheminement: {accountDetails?.routing_number}
                  </p>
                  <p
                    style={{
                      margin: '5px 0',
                      fontSize: 'small',
                      fontWeight: 'bold'
                    }}>
                    Pays: {accountDetails?.country}
                  </p>
                  <p
                    style={{
                      margin: '5px 0',
                      fontSize: 'small',
                      fontWeight: 'bold'
                    }}>
                    Devise: {accountDetails?.currency}
                  </p>
                </div>
              </div>
              <br />
              <div className={styles.modal_flex}>
                <p>Transactions</p>
              </div>
              <div
                style={{ overflowY: 'auto', maxHeight: 'calc(45vh - 100px)' }}>
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      {/* <th style={thStyle}>S.no</th> */}
                      <th style={thStyle}>Montant</th>
                      <th style={thStyle}>Arrivé / Estimé</th>
                      <th style={{ ...thStyle, borderRight: '1px solid #ddd' }}>
                        Statut
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!transactions?.length ? (
                      transactions.map((transaction, index) => (
                        <tr
                          key={transaction.id}
                          style={index % 2 === 0 ? evenRowStyle : {}}>
                          {/* <td style={{ ...tdStyle, ...firstColumnStyle }}>{index + 1}</td> */}
                          <td
                            style={{
                              ...tdStyle,
                              color:
                                transaction.status === 'pending'
                                  ? '#ef233c'
                                  : transaction.status === 'available'
                                  ? '#22bb33'
                                  : '#22bb33'
                            }}>
                            {transaction.source.amount / 100}€
                          </td>
                          <td
                            style={{
                              ...tdStyle,
                              color:
                                transaction.status === 'pending'
                                  ? '#ef233c'
                                  : transaction.status === 'available'
                                  ? '#22bb33'
                                  : '#22bb33'
                            }}>
                            {transaction.status === 'pending'
                              ? moment(transaction.available_on * 1000).format(
                                  'dddd, MMMM Do YYYY'
                                )
                              : '-'}
                          </td>
                          <td
                            style={{
                              ...tdStyle,
                              color:
                                transaction.status === 'pending'
                                  ? '#ef233c'
                                  : transaction.status === 'available'
                                  ? '#22bb33'
                                  : '#22bb33',
                              borderRight: '1px solid #ddd'
                            }}>
                            <div
                              style={{
                                backgroundColor:
                                  transaction.status === 'pending'
                                    ? '#FFD1D1'
                                    : transaction.status === 'available'
                                    ? '#D1FFD1'
                                    : '',
                                padding: '3px',
                                borderRadius: '5px',
                                textAlign: 'center'
                              }}>
                              {transaction.status}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <p style={{ padding: 10, fontFamily: 'Poppins' }}>
                        No Transactions
                      </p>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  ...tableStyle,
                  marginTop: '20px',
                  borderBottom: '1px solid #ddd'
                }}>
                <div style={{ borderBottom: '1px solid #ddd' }}>
                  <thead>
                    <tr>
                      <th style={{ padding: '10px' }}>Total</th>
                    </tr>
                  </thead>
                </div>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontWeight: 'bold',
                        color: '#22bb33',
                        padding: '10px',
                        fontFamily: 'Poppins'
                      }}>
                      {totalAmount}€
                    </td>
                  </tr>
                </tbody>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export function ConfirmDelete({
  onClick,
  title,
  open,
  setOpen,
  setCurrentSelected
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    setCurrentSelected(null);
    dispatch(set_close_modal());
  };
  const isMobile = useTabletOrMobile();

  function close() {
    dispatch(set_close_modal());
    setCurrentSelected(null);
  }

  const { deletes } = useSelector(state => state.modalReducer);
  useEffect(() => {
    setOpen(deletes);
  }, [deletes]);

  function handleDelete() {
    onClick();
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div style={isMobile ? styleMobile : style}>
          <div className={styles.modal_data_delete}>
            <div className={styles.container}>
              <div className={styles.modal_flex}>
                <p>Voulez vous supprimer {title}</p>
              </div>

              <div className={styles.btn_f}>
                <button className={styles.btn} onClick={handleDelete}>
                  Oui
                </button>
                <button className={styles.btn_no} onClick={close}>
                  Non
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
