import React, { useEffect, useState } from "react";
import { StatusChangeToken } from "../../utils/functions";
import Footer from '../../layout/footer/footer';
import styles from './style.module.scss';
import logo from '../../assets/svg/logo.svg';
import { deleteProductFromCart } from "../../redux/cart";
import { useDispatch } from "react-redux";

const SuccessPayment = () =>{
    const dispatch = useDispatch()
    const useStatus = StatusChangeToken()
    const [error,setError] = useState(false)
    useEffect(() => {
        // Parse URL parameters when component mounts
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        console.log(window.location.href);
        const paymentIntentParam = params.get("payment_intent");
        console.log(paymentIntentParam);
        if(paymentIntentParam)
        useStatus.mutate(paymentIntentParam,{
        onSuccess:(data =>{
            dispatch(deleteProductFromCart())
            setTimeout(() => {
                
                window.location.href='/shop/'+data.shop
            }, 10000);
            setError(false)
        }),
        onError:(data =>{
            setError(true)
        })
    })
        
    }, []);

    return(
        <div className={styles.box_seller}>
            
        <div className={styles.flex}>
            <div className={styles.main_box_container}>
            <div className={styles.logo}>
                <img src={logo} alt="" />
            </div>
                {!error ?
                    <div className={styles.box}>
                        <div className={styles.auth_box}>
                            <div className={styles.img_succ}>
                                <i className="fa-solid fa-3x fa-circle-check" ></i>
                            </div>
                            <p>Paiement est réussie, vous serez redirigé vers le shop.</p>
                        </div>
                    </div>
                 : 
                    <div className={styles.box}>
                    
                        <div className={styles.auth_box}>
                    <div className={styles.img_fialed}>
                        <i className="fa-solid  fa-3x fa-circle-xmark"></i>
                        </div>
                        <p>Paiement n'est pas réussie, vous serez redirigé vers le shop. </p>
                        
                    </div>
                    </div>
                }
               
                
            </div>
        </div>
        <Footer></Footer>
    </div>
    )

}

export default SuccessPayment;