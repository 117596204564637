import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    show_product: false,
    add_product: false,
    modify_product: false,
    add_shop: false,
    modify_shop: false,
    get_order: false,
    settings:false,
    deletes:false
}

const modalSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        set_get_order: (state, action) => {
            state.deletes = false;
            state.show_product = false;
            state.add_product = false;
            state.add_shop = false;
            state.settings = false;
            state.modify_shop= false;
            state.get_order= true;
            state.modify_product = false;
        },
        set_show_product: (state, action) => {
            state.deletes = false;
            state.show_product = true;
            state.add_product = false;
            state.add_shop = false;
            state.settings = false;
            state.modify_shop= false;
            state.modify_product = false;
            state.get_order= false;
        },
        set_modify_shop: (state, action) => {
            state.deletes = false;
            state.show_product = false;
            state.add_product = false;
            state.add_shop = false;
            state.settings = false;
            state.modify_shop= true;
            state.modify_product = false;
            state.get_order= false;
        },
        set_add_product: (state, action) => {
            state.deletes = false;
            state.show_product = false;
            state.add_product = true;
            state.add_shop = false;
            state.settings = false;
            state.modify_product = false;
            state.modify_shop= false;
            state.get_order= false;
        },
        set_modify_product: (state, action) => {
            state.deletes = false;
            state.show_product = false;
            state.modify_product = true;
            state.add_product = false;
            state.add_shop = false;
            state.settings = false;
            state.modify_shop= false;
            state.get_order= false;
        },
        set_add_shop: (state, action) => {
            state.deletes = false;
            state.show_product = false;
            state.add_product = false;
            state.add_shop = true;
            state.settings = false;
            state.modify_product = false;
            state.modify_shop= false;
            state.get_order= false;
        },
        set_add_settings: (state, action) => {
            state.deletes = false;
            state.show_product = false;
            state.add_product = false;
            state.add_shop = false;
            state.settings = true;
            state.modify_product = false;
            state.modify_shop= false;
            state.get_order= false;
        },
        set_handle_deletes: (state, action) => {
            state.deletes = true;
            state.show_product = false;
            state.add_product = false;
            state.add_shop = false;
            state.settings = false;
            state.modify_product = false;
            state.modify_shop= false;
            state.get_order= false;
        },
        set_close_modal: (state, action) => {
            state.show_product = false;
            state.add_product = false;
            state.settings = false;
            state.add_shop = false;
            state.modify_product = false;
            state.deletes = false;
            state.modify_shop= false;
            state.get_order= false;
        },
    },
});

export const {
    set_show_product,
    set_add_product,
    set_add_shop,
    set_add_settings,
    set_close_modal,
    set_modify_product,
    set_modify_shop,
    set_get_order,
    set_handle_deletes
} = modalSlice.actions;
export default modalSlice.reducer;