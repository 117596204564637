import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './style.module.scss'

const NotFoundPage = () => {
  // Define spring animation for the text
  // const textAnimation = useSpring({
  //   from: { opacity: 0, transform: 'translateY(-50px)' },
  //   to: { opacity: 1, transform: 'translateY(0)' },
  //   config: { duration: 1000 }
  // });

  // Define spring animation for the emoji
  // const emojiAnimation = useSpring({
  //   from: { opacity: 0, transform: 'scale(0.5)' },
  //   to: { opacity: 1, transform: 'scale(1)' },
  //   config: { duration: 1000, delay: 500 }
  // });

  useEffect(() => {
    window.location.href = "https://albertsav.com/decouvrez-albert-extra";
  }, [])

  return (
    <></>
//     <div className={styles["not-found-container"]}>
//       <animated.h1 style={textAnimation}>
// 404 Page non trouvée</animated.h1>
//       <animated.span style={emojiAnimation} role="img" aria-label="Crying emoji">Un jeton est requis pour accéder aux données😢</animated.span>
//     </div>
  );
};

export default NotFoundPage;
