import React from "react";
import styles from './style.module.scss';
import plus from '../../assets/svg/plus.svg'

const TagInput = ({title,subTile,text_btn,onClick,isOption = false}) => {
    return (
        <div className={styles.tagline_header}>
            <div className={styles.description}>
                <p className={styles.title}>{title}</p>
                <p className={styles.sub}>{subTile}</p>

            </div>
            <div className={styles.descriptions}>
            {   !isOption ? 
                    <button onClick={onClick} className={styles.text_btn}> <img src={plus} alt="plus" />  {text_btn}</button>
                    :
                    <select >
                    <option value="temps">Sélectionner</option>
                    <option value="">A venir </option>
                    <option value="">Passées</option>
                </select>}
                

            </div>
        </div>
    )
}

export default TagInput;