import customAxios from "../axios/custom";

export async function Get({ from, data }) {
  const res = await customAxios.post("/api/v1/payment/" + from, data);
  return res.data;
}
export async function Post({ from, data }) {
  const res = await customAxios.post("/api/v1/payment/" + from, data);
  return res.data;
}
