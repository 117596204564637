import React, { useEffect } from 'react'
import styles from './style.module.scss'
import one from '../../../assets/images/1.png'
import two from '../../../assets/images/2.png'
import three from '../../../assets/images/3.png'
import TagInput from '../../../layout/tag-input/tag'
import CardShopping from '../../../layout/card-items/card-items'
import { useDispatch, useSelector } from 'react-redux'
import {
	set_add_product,
	set_add_shop,
	set_show_product,
} from '../../../redux/modal'
import {
	ConfirmDelete,
	ModalAddProduct,
	ModalChoseProduct,
	ModalShowProduct,
} from '../../../layout/modals/modals'
import { selectshops } from '../../../redux/shop'
import StripeAccountModal from '../../../layout/modals/addInfoModal'

const PROD = [
	{
		title: 'Cozy Studio',
		img: one,
	},
	{
		title: 'Loft Paris',
		img: two,
	},
	{
		title: 'Cavern In Alpes',
		img: three,
	},
]

const ShopCard = ({ title, subtitl, bankInfoStatus }) => {
	const dispatch = useDispatch()
	const [addInfoOpen, setAddInfoOpen] = React.useState(false)

	function handleOpenModal() {
		dispatch(set_add_product())
	}

	const shops = useSelector(selectshops)

	useEffect(() => {
		console.log(shops)
	}, [shops])

	const handleAddAccount = () => {
		setAddInfoOpen(true)
	}

	const handleAddInfoClose = () => {
		setAddInfoOpen(false)
	}

	return (
		<div className={styles.order_box}>
			<TagInput
				title={title}
				subTile={subtitl}
				text_btn={'Ajouter une boutique'}
				onClick={
					bankInfoStatus !== 'verified'
						? handleAddAccount
						: handleOpenModal
				}
			/>
			<ModalAddProduct />
			{bankInfoStatus !== 'verified' && <StripeAccountModal isOpen={addInfoOpen} onClose={handleAddInfoClose}/>}

			<div className={styles.prod_select}>
				{shops.map((x, index) => {
					return (
						<React.Fragment key={x._id}>
							<CardShopping
								title={x.title}
								id={x._id}
								img={x.photo}
								key={x._id}
								prod={x}
								type={'shop'}
							></CardShopping>
						</React.Fragment>
					)
				})}
			</div>
		</div>
	)
}

export default ShopCard
