import React, { useState } from "react";
import styles from "./style.module.scss";
import { useDispatch } from "react-redux";
import { set_add_settings } from "../../redux/modal";
import { SettingsUsers, Transactions } from "../modals/modals";
import transaction from "../../assets/svg/transaction.svg";
import AddBankAccount from "../../components/AddBankAccount";
import { Box, IconButton } from "@mui/material";
import { albertEmail } from "../../utils/constants";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Link } from "react-router-dom";

const TagText = ({ title, subTile, img, userData }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <div className={styles.tagline_header}>
      <div className={styles.tab} style={{ minHeight: "80px" }}>
        <p className={styles.title}>{title}</p>
        <p className={styles.sub}>{subTile}</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}>
        <div>
          <SettingsUsers></SettingsUsers>{" "}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {title === "Statistiques Meilleures ventes" &&
              albertEmail === userData?.email && (
                <IconButton component={Link} to='/payments'>
                  <PaymentsIcon fontSize="large" sx={{ color: "#1CA1E2" }} />
                </IconButton>
              )}
            {title === "Statistiques Meilleures ventes" && (
              <AddBankAccount sx={{ color: "#1CA1E2" }} />
            )}
            {img && (
              <div
                className={styles.tab}
                onClick={(e) => dispatch(set_add_settings())}>
                <img src={img} alt='img' />
              </div>
            )}
          </Box>
        </div>
        <div>
          <Transactions open={open} setOpen={setOpen} />
          {img && (
            <div className={styles.tab} onClick={(_) => setOpen(true)}>
              <img
                src={transaction}
                alt='img'
                className={styles.transactionIcon}
                style={{ marginRight: "3px" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TagText;
