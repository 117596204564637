import React from 'react'
import style from './style.module.scss'
import img from '../../../assets/images/cover.png'
const HeaderImg = ({ photo, onError = () => null }) => {
	return (
		<div className={style.container}>
			<div className={style.img_header}>
				<img
					src={photo}
					alt={'img'}
					onError={onError}
				/>
			</div>
		</div>
	)
}

export default HeaderImg
