import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import HeaderImg from '../../components/shop/header/header'
import HeaderText from '../../components/shop/header-text/header-text'
import ProductBox from '../../components/shop/product/product'
import cover from '../../assets/images/cover.png'
import CalenderPart from '../../components/shop/calendar/calendar'
import Footer from '../../layout/footer/footer'
import { useGetIdLogin, useGetToken } from '../../utils/functions'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { deleteProductFromCart } from '../../redux/cart'
const PRODUCT = [
	{
		title: 'PACK LOVE',
		price: '59,00',
		image: cover,
		description:
			'Décoration romantique avec pétales de roses + Bouteille de Champagne + Madeleines + Crackers',
		detailed_description:
			'Régalez vous pendant votre séjour avec une bouteille de champagne bien fraîche préparée avant votre arrivée. La bouteille de champagne est fournie avec amuse bouche et madeleines. Une décoration romantique avec des pétales de roses sera mises sur le lit. (Cliquez sur VOIR PLUS - pour voir des photos)',
	},
	{
		title: 'PACK LOVE',
		price: '59,00',
		image: cover,
		description:
			'Décoration romantique avec pétales de roses + Bouteille de Champagne + Madeleines + Crackers',
		detailed_description:
			'Régalez vous pendant votre séjour avec une bouteille de champagne bien fraîche préparée avant votre arrivée. La bouteille de champagne est fournie avec amuse bouche et madeleines. Une décoration romantique avec des pétales de roses sera mises sur le lit. (Cliquez sur VOIR PLUS - pour voir des photos)',
	},
]

const Shop = () => {
	const { token } = useParams()
	const dispatch = useDispatch()
	// const navigate = useNavigate()

	// const { isError: isLoginError } = useGetIdLogin()
	const { data, isLoading, isError } = useGetToken(token)
	const [imageError, setImageError] = useState(false)

	const handleImageError = () => {
		setImageError(true)
	}
	const error = useSelector((state) => state.cartReducer.isError)
	const cartItems = useSelector((state) => state.cartReducer)

	// useEffect(() => {
	// 	if (isLoginError == true) navigate('/notoken')
	// }, [isLoginError])

	useEffect(() => {
		const checker = cartItems?.items?.filter((v) => v?.shopId === data?._id)
		if (data && !checker.length) {
			dispatch(deleteProductFromCart())
		}
	}, [data])

	return (
		<div className='shop'>
			<Helmet>
				<meta
					name='description'
					content={`boutique ${data?.title}`}
				/>
				<title>{`Boutique ${
					data?.title
						? data?.title?.slice(0, 1)?.toUpperCase() +
						  data?.title?.slice(1)?.toLowerCase()
						: ''
				}`}</title>
			</Helmet>
			{data && !isLoading && (
				<HeaderImg
					photo={!imageError ? data.photo : '/shop-image.jpg'}
					onError={handleImageError}
				></HeaderImg>
			)}

			{data && !isLoading && (
				<div className={styles.container_box}>
					<div className={styles.w_80}>
						<HeaderText></HeaderText>
						<div className={styles.card_prod}>
							<div
								className={
									error
										? styles.w_60 +
										  ' ' +
										  styles.opacity_drop
										: styles.w_60
								}
							>
								{data.products.map((prod, index) => {
									return (
										<ProductBox
											product={{
												...prod,
												shopId: data._id,
											}}
											key={index}
										></ProductBox>
									)
								})}
							</div>
							<div className={styles.w_40}>
								<CalenderPart product={data}></CalenderPart>
							</div>
						</div>
					</div>
				</div>
			)}
			<Footer></Footer>
		</div>
	)
}

export default Shop
