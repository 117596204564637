import { combineReducers, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import modalSlice from './modal';
import shopSlice from './shop';
import productSlice from './product';
import cartSlice from './cart';

// Configuring persist options
const persistConfig = {
  key: 'root',
  storage,
};

// Combining reducers
const rootReducer = combineReducers({
  modalReducer: modalSlice,
  shopReducer: shopSlice,
  productReducer: productSlice,
  cartReducer: cartSlice,
});

// Creating a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Creating the Redux store
export const store = createStore(persistedReducer, {});

// Creating the persistor
export const persistor = persistStore(store);