import React, { useState } from 'react'
import style from './style.module.scss'

import {
	PaymentElement,
	Elements,
	useStripe,
	useElements,
	CardElement,
} from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const CheckoutForm = () => {
	const elements = useElements()
	const stripe = useStripe()
	let { token } = useParams()
	const [errorMessage, setErrorMessage] = useState('')
	const { date } = useSelector((state) => state.cartReducer)
	const { items, isError } = useSelector((state) => state.cartReducer)

	const backendUrl =
		'https://admin-extra.albertsav.com/api/v1/payment/create-payment-intent'
	const [email, setEmail] = useState('')
	const [nom, setNom] = useState('')
	const [length, setLength] = useState(false)
	const [loading, setLoading] = useState(false)
	const handleSubmit = async (event) => {
		try {
			event.preventDefault()
			setLoading(true)
			if (elements == null || stripe == null) {
				setLoading(false)
				return
			}
			if (items.length > 0) {
				setLength(false)
				if (!isError) {
					const { error: submitError } = await elements.submit()
					if (submitError?.message) {
						// Show error to your customer
						toast(submitError.message, { type: 'error' })
						setErrorMessage(submitError.message)
						setLoading(false)
						return
					}
					const amount = items.reduce((total, item) => {
						const productPrice = item.price
						const quantity = item.quantity
						return total + productPrice * quantity
					}, 0)
					const arr = []
					items.map((x) => {
						return arr.push({ prod: x._id, quantity: x.quantity })
					})
					const res = await fetch(backendUrl, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization:
								'Bearer ' + localStorage.getItem('token'),
						},
						body: JSON.stringify({
							currency: 'eur',
							amount,
							nom,
							email,
							token,
							products: arr,
							dateOffre: date,
						}),
					})

					const response = await res.json()
					console.log({ response })
					if (!!response?.clientSecret) {
						const { error, paymentIntent } =
							await stripe.confirmPayment({
								elements,
								clientSecret: response.clientSecret,
								payment_method: {
									card: elements.getElement(CardElement),
									billing_details: {
										email: email, // Include the email here
									},
								},
								confirmParams: {
									return_url: `${window.location.origin}/success-payment`,
								},
							})

						console.log(paymentIntent)

						if (error) {
							setErrorMessage(error.message)
							console.log(error.message)
							toast(error.message, { type: 'error' })
							setLoading(false)
						}
					} else {
						throw {
							message: 'Something went wrong! Please try again.',
						}
					}
				}
			} else {
				setLength(true)
				setLoading(false)
			}
		} catch (err) {
			setLoading(false)
			toast(err.message, { type: 'error' })
		}
	}

	return (
		<form
			onSubmit={handleSubmit}
			className={style.formPay}
		>
			<PaymentElement />
			<div className={style.form_input}>
				<label htmlFor='email'>Email</label>
				<input
					type='email'
					placeholder='Email Address'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
				/>
			</div>

			<div className={style.form_input}>
				<label htmlFor='email'>Nom Complet </label>
				<input
					type='text'
					placeholder='Nom complet'
					value={nom}
					onChange={(e) => setNom(e.target.value)}
					required
				/>
			</div>

			{/* Show error message to your customers */}
			<div className={style.btn}>
				<button disabled={!stripe || loading}>Payer</button>
				{length && (
					<p className={style.error}>Votre painer est vide !</p>
				)}
				{isError == true && (
					<p className={style.error}>
						La date saisie n'est pas valide
					</p>
				)}
			</div>
		</form>
	)
}
