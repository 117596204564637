import { useMediaQuery } from "react-responsive";

export const useTabletOrMobile = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });
    return isTabletOrMobile;
  };
  export  const useDesktop = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(min-width: 1600px)' });
    return isTabletOrMobile;
  };
