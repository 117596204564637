import React, { useEffect } from "react";
import Seller from "../../components/profile/seller/seller";
import styles from "./style.module.scss";
import logo from "../../assets/svg/logo.svg";
import set from "../../assets/svg/Settings.svg";
import Stats from "../../components/profile/statistics/statistics";
import Order from "../../components/profile/orders/order";
import Product from "../../components/profile/produit/product";
import ShopCard from "../../components/profile/boutique/boutique";
import Footer from "../../layout/footer/footer";
import { useGetAll, useGetIdLogin } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { checkVerificationStatus } from "../../api/product";

const SellerPage = () => {
  const product = useGetAll("product");
  const shop = useGetAll("shop");
  const [bankInfoStatus, setBankInfoStatus] = React.useState("");

  useEffect(() => {
    product.refetch();
    shop.refetch();
    refetch();
  }, []);

  const { data, isLoading, isError, refetch } = useGetIdLogin();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError == true) navigate("/home");
  }, [data, isLoading, isError]);

  const checkBankInfoStatus = async (data) => {
    checkVerificationStatus({ accountId: data.back_acc })
      .then((res) => {
        if (res === "Verified") {
          return setBankInfoStatus("verified");
        }
        setBankInfoStatus("not verified");
      })
      .catch((err) => {
        setBankInfoStatus("not verified");
        console.log(err.message);
        return err.message;
      });
  };

  useEffect(() => {
    if (data) {
      checkBankInfoStatus(data);
    }
  }, [data]);

  return (
    <div className={styles.box_seller}>
      <Helmet>
        <meta name='description' content={`boutique ${data?.username}`} />
        <title>{`Boutique ${data?.username}`}</title>
      </Helmet>

      <div className={styles.flex}>
        <div className={styles.main_box_container}>
          <div className={styles.logo}>
            <img src={logo} alt='' />
          </div>

          <div className={styles.box}>
            <div className={styles.w_48}>
              <Stats
                title={"Statistiques"}
                subtitl={
                  "Ici vous trouvez vos statistiques de boutiques"
                }></Stats>
            </div>
            <div className={styles.w_48}>
              <Seller
                data={data}
                title={"Statistiques Meilleures ventes"}
                subtitl={"Ici vous trouvez vos Meilleures ventes"}
                img={set}></Seller>
            </div>
          </div>
          <div className={styles.w_100}>
            <Order
              title={"Commandes"}
              subtitl={"Ici vous trouvez vos Commandes"}></Order>
          </div>
          <div className={styles.w_100}>
            <ShopCard
              bankInfoStatus={bankInfoStatus}
              title={"Boutiques"}
              subtitl={"Ici vous trouvez vos boutiques "}></ShopCard>
          </div>
          <div className={styles.w_100}>
            <Product
              bankInfoStatus={bankInfoStatus}
              title={"Produits"}
              subtitl={"Ici vous trouvez vos produits"}></Product>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default SellerPage;
