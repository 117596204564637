import React from "react";
import styles from './style.module.scss';

const HeaderText = () =>{

    return(
        <div className={styles.headlines}>
            <div className={styles.title}>
                <p>Agrémentez votre séjour  ! 🎁</p>
            </div>
            <div className={styles.description}>
                <p>(prix ttc - facture transmise par e-mail après à votre commande 
                    {/* - les commandes doivent être effectuées au plus tard 24 heures avant votre arrivée.  */}
                    )</p>
            </div>
        </div>
    )

}

export default HeaderText;