import React from 'react';
import style from './style.module.scss'


const ConfirmationModal = ({ message, onYes, onNo }) => {
  return (
    <div className={style['confirmation-modal']} style={{ zIndex: 10 }}>
      <div className={style["modal-content"]}>
        <p>{message}</p>
        <br />
        <div className={style["button-container"]}>
          <button onClick={onYes}>Yes</button>
          <button onClick={onNo} id='btn-no'>No</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
