import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import add from '../../../assets/svg/add.svg'
import minus from '../../../assets/svg/mminus.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
	addProductToCart,
	dayCountHandle,
	deleteProductFromCart,
	errorHandle,
	handleDate,
	minusProductToCart,
	removeProduct,
} from '../../../redux/cart'
import Payment from '../../../layout/payment/payment'
import { showSuccessToast } from '../../../layout/toast/toast'
import ConfirmationModal from './confirmation'
const CalenderPart = ({ product }) => {
	const [time, setTime] = useState(moment('2022-04-17T18:30'))
	const [selectedDate, setSelectedDate] = useState(null)
	const [error, setError] = useState('')
	const [difference, setDifference] = useState('')
	const [showModal, setShowModal] = useState(false)
	const dispatch = useDispatch()
	const handleDateChange = (newDate) => {
		console.log()
		setSelectedDate(newDate)
		const today = moment().startOf('day')
		const diff = newDate.diff(today, 'days')
		if (newDate.isSameOrBefore(today, 'day')) {
			dispatch(errorHandle(true))
		} else {
			dispatch(errorHandle(false))
			dispatch(dayCountHandle(diff))
			dispatch(handleDate(moment(newDate).format('YYYY-MM-DD')))
		}
	}

	function handleVide() {
		dispatch(deleteProductFromCart())
		showSuccessToast('Panier a été supprimé avec succès !')
	}

	const cartItems = useSelector((state) => state.cartReducer)
	// console.log({ cartItems })
	function handleAddCart(x) {
		dispatch(addProductToCart(x))
	}

	function handleMinusCart(x) {
		if (x.quantity > 1) dispatch(minusProductToCart(x))
		else dispatch(removeProduct(x))
	}

	const [currQuantity, setCurrQuantity] = useState(0)
	const [tva, setTva] = useState(0)

	useEffect(() => {
		if (product.vat == 'true' && product.vatTaxPercentage) {
			setCurrQuantity(
				cartItems.items.reduce((total, item) => {
					const productPrice = item.price
					const quantity = item.quantity
					return (
						total +
						productPrice * quantity +
						productPrice *
							quantity *
							(parseInt(product.vatTaxPercentage) / 100)
					)
				}, 0)
			)
			setTva(
				cartItems.items.reduce((total, item) => {
					const productPrice = item.price
					const quantity = item.quantity
					return (
						total +
						productPrice *
							quantity *
							(parseInt(product.vatTaxPercentage) / 100)
					)
				}, 0)
			)
		} else {
			setCurrQuantity(
				cartItems.items.reduce((total, item) => {
					const productPrice = item.price
					const quantity = item.quantity
					return total + productPrice * quantity
				}, 0)
			)
		}
	}, [cartItems])

	const handleConfirmation = (answer) => {
		if (answer === 'yes') {
			handleVide()
		}
		setShowModal(false)
	}

	return (
		<div className={style.checkout}>
			<div className={style.calendar}>
				<div className={style.title}>
					<p>TOP DESTINATION-ALBERT EXTRA</p>
				</div>
				<div className={style.box}>
					<p>Saisissez votre date d'arrivée</p>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							value={selectedDate}
							onChange={handleDateChange}
							minDate={moment().add(1, 'days')}
							sx={{ width: '100%' }}
						/>
					</LocalizationProvider>
				</div>
			</div>
			<div className={style.shop}>
				<div className={style.title}>
					<p>Panier</p>
				</div>
				<div className={style.box}>
					<p>Extras</p>
					<div className={style.config}>
						{cartItems.items.length > 0 &&
							cartItems.items.map((x) => {
								return (
									<div className={style.addons}>
										<div className={style.dflex}>
											<img
												src={minus}
												alt=''
												onClick={(e) =>
													handleMinusCart(x)
												}
											/>
											<p>{x.quantity}</p>
											<img
												src={add}
												alt=''
												onClick={(e) =>
													handleAddCart(x)
												}
											/>
										</div>
										<p>{x.title}</p>
									</div>
								)
							})}
					</div>
				</div>

				<div className={style.sub_total}>
					<p>Sous-total</p>
					<p>{currQuantity - tva}€</p>
				</div>
				{product.vat == 'true' && product.vatTaxPercentage && (
					<div className={style.sub_total}>
						<p>TVA {product.vatTaxPercentage}%</p>
						<p>{tva?.toFixed(2)}€</p>
					</div>
				)}

				<div className={style.total}>
					<p>Total</p>
					<p>{currQuantity}€</p>
				</div>
				<div className={style.btnEmpty}>
					<button onClick={() => setShowModal(true)}>
						Vider le panier
					</button>
				</div>
				{showModal && (
					<ConfirmationModal
						message='
Êtes-vous sûr de vouloir continuer en vidant le panier ?'
						onYes={() => handleConfirmation('yes')}
						onNo={() => handleConfirmation('no')}
					/>
				)}
			</div>
			<div className={style.shop}>
				<Payment></Payment>
			</div>
		</div>
	)
}

export default CalenderPart
