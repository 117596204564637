import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { userLogin } from "../../api/product";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Create, Update } from "../../api/external-bank";
import ExternalAccounts from "./ExternalAccounts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 500,
  p: 2.5,
  backgroundColor: "rgba(255, 255, 255, 0.75)",
  borderRadius: "16px",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(15.3px)",
  WebkitBackdropFilter: "blur(15.3px)",
  border: "1px solid rgba(255, 255, 255, 0.08)",
  outline: "none",
};

const buttonStyle = {
  borderRadius: 2.5,
  backgroundImage: "linear-gradient(45deg, #5971fe 55%, transparent)",
  backgroundColor: "palevioletred",
};

const AddBankAccount = ({ sx }) => {
  const [bankAccounts, setBankAccounts] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(!bankAccounts.length);

  const initialValues = {
    id: "",
    account_holder_name: "",
    account_holder_type: "individual",
    // routing_number: '',
    account_number: "",
  };
  const getId = async () => {
    try {
      const token = localStorage.getItem("token_verif");
      const response = await userLogin({ token });
      if (!response) {
        throw new Error("Failed to get user");
      }
      return response;
    } catch (error) {
      // Handle errors appropriately
      console.error("Error fetching user:", error);
      throw error;
    }
  };

  const {
    data: user,
    isLoading,
    refetch,
    isError,
  } = useQuery({
    queryKey: ["get-one-vvcccccccc"],
    queryFn: getId,
    refetchOnWindowFocus: false,
    enabled: true,
    retry: true,
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: initialValues,
  });

  const handleClose = () => {
    reset(initialValues);
    setOpen(false);
  };

  const handleAddBankAccount = async (values) => {
    try {
      if (values.id) {
        console.log("update", values);
        const response = await Update({
          from: `external-bank/${user.back_acc}/${values.id}`,
          data: { ...values },
        });
        console.log(response.externalAccount);
        response?.externalAccount &&
          setBankAccounts((prev) =>
            prev.map((v) =>
              v.id === values.id ? { ...response.externalAccount } : v
            )
          );
      } else {
        const response = await Create({
          from: "external-bank/create",
          data: { ...values, accountId: user.back_acc, email: user.email },
        });
        response.externalAccount &&
          setBankAccounts((prev) => [...prev, response.externalAccount]);
      }
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (watch("id")) {
      setExpanded(true);
    }
  }, [watch("id")]);

  return (
    <div>
      {/* <Button
        disabled={isLoading}
        variant="contained"
        sx={{ ...sx }}
        onClick={() => setOpen(true)}>
        Ajouter un compte bancaire
      </Button> */}
      <IconButton
        onClick={() => setOpen(true)}
        sx={{
          ...sx,
          position: "relative",
          "& span": {
            position: "absolute",
            top: 0,
            right: 0,
          },
        }}
        color='primary'>
        <AccountBalanceOutlinedIcon fontSize='large' />
        {/* <span>+</span> */}
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box
          sx={{
            ...boxStyle,
            "& form": {
              display: "flex",
              flexDirection: "column",
              gap: 1,
            },
          }}>
          <ExternalAccounts
            user={user}
            reset={reset}
            bankAccounts={bankAccounts}
            setBankAccounts={setBankAccounts}
            id={watch("id")}
            expanded={expanded}
            handleAdd={() => {
              reset(initialValues);
              setExpanded(true);
            }}
          />
          <Accordion
            expanded={expanded}
            disableGutters
            sx={{
              borderRadius: "0.8rem!important",
              "&.MuiPaper-root::before": {
                backgroundColor: "transparent!important",
              },
            }}
            onChange={(e, newExpanded) => setExpanded(!!newExpanded)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              sx={{
                mt: 2,
                minHeight: "20px!important",
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  my: 2,
                },
              }}>
              {watch("id") ? "Compte mis à jour" : "Ajouter un compte bancaire"}
            </AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handleSubmit(handleAddBankAccount)}>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <FormControl fullWidth>
                    <FormLabel id='account_holder_name'>
                      Nom du titulaire du compte
                    </FormLabel>
                    <TextField
                      size='small'
                      variant='outlined'
                      {...register("account_holder_name", {
                        required: {
                          message: "Ceci est nécessaire!",
                          value: true,
                        },
                      })}
                      placeholder='Nom du titulaire du compte'
                    />
                    {errors.account_holder_name && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        Ceci est nécessaire!
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel id='account_holder_type'>
                      Type de titulaire du compte
                    </FormLabel>
                    <Select
                      {...register("account_holder_type")}
                      defaultValue={"individual"}
                      size='small'
                      labelId='account_holder_type'
                      id='account_holder_typeHelper'
                      label='Account Holder Type'>
                      <MenuItem value={"individual"}>Individuel</MenuItem>
                      <MenuItem value={"company"}>Entreprise</MenuItem>
                    </Select>
                    {errors.account_holder_type && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        Ceci est nécessaire!
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                {/* <FormControl fullWidth>
                  <FormLabel id="routing_number">Routing Number</FormLabel>
                  <TextField
                    size="small"
                    variant="outlined"
                    disabled={!!watch('id')}
                    {...register('routing_number', {
                      required: {
                        message: 'Ceci est nécessaire!',
                        value: !!watch('id') ? false : true
                      },
                      pattern: {
                        value: /^[0-9]{9}$/,
                        message: 'Invalid routing number'
                      }
                    })}
                    placeholder="Routing Number"
                  />
                  {errors.routing_number && (
                    <FormHelperText sx={{ color: 'error.main' }}>
                      {errors.routing_number?.message || 'Ceci est nécessaire!'}
                    </FormHelperText>
                  )}
                </FormControl> */}
                <FormControl fullWidth>
                  <FormLabel id='account_number'>IBAN</FormLabel>
                  <TextField
                    disabled={!!watch("id")}
                    size='small'
                    variant='outlined'
                    {...register("account_number", {
                      required: {
                        message: "Ceci est nécessaire!",
                        value: !!watch("id") ? false : true,
                      },
                    })}
                    value={
                      !!watch("last4") ? `.... .... ${watch("last4")}` : null
                    }
                    placeholder='IBAN'
                  />
                  {errors.account_number && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.account_number?.message || "Ceci est nécessaire!"}
                    </FormHelperText>
                  )}
                </FormControl>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={isSubmitting}
                  sx={{ ...buttonStyle, mt: 1, mx: "auto", minWidth: 200 }}>
                  {isSubmitting ? (
                    <CircularProgress size={25} sx={{ color: "white" }} />
                  ) : (
                    "Soumettre"
                  )}
                </Button>
              </form>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Modal>
    </div>
  );
};

export default AddBankAccount;
