import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import right from '../../../assets/svg/right.svg'
import left from '../../../assets/svg/left.svg'
import calendar from '../../../assets/svg/calendar.svg'
import mince from '../../../assets/svg/minus.svg'
import plus from '../../../assets/svg/plus.svg'
import { useDispatch, useSelector } from 'react-redux'
import { addProductToCart, minusProductToCart } from '../../../redux/cart'

const ProductBox = ({ product }) => {
	const [index, setIndex] = useState(0)

	const addIndexPlus = () => {
		if (index >= 0 && index < product.photos.length - 1) {
			setIndex((old) => old + 1)
		}
	}

	const addIndexMinus = () => {
		if (index > 0) {
			setIndex((old) => old - 1)
		}
	}

	const [oneProd, setoneProd] = useState()

	useEffect(() => {
		setoneProd(product)
	}, [product])

	const dispatch = useDispatch()

	const cartItems = useSelector((state) => state.cartReducer)
	// console.log({ cartItems })

	function handleAddCart() {
		dispatch(addProductToCart(oneProd))
	}

	function handleMinusCart() {
		dispatch(minusProductToCart(oneProd))
	}

	// const [currQuantity, setCurrQuantity] = useState(0)

	let item = cartItems.items.find((x) => x._id == oneProd?._id)
	const currQuantity = item?.quantity || 0
	// useEffect(() => {

	// 	if (item) setCurrQuantity(item.quantity)
	// 	else {
	// 		setCurrQuantity(0)
	// 	}
	// }, [cartItems])

	const { isError } = useSelector((state) => state.cartReducer)
	const { dayCount } = useSelector((state) => state.cartReducer)

	useEffect(() => {
		console.log(isError, dayCount)
	}, [isError, dayCount])

	return (
		<div className={styles.box}>
			<div className={styles.container}>
				<div className={styles.header_img}>
					<img
						src={product.photos[index]}
						alt=''
					/>
					{/* <div className={styles.date}>   
                        {isError == null && <p>Précisez votre date d’arrivée</p>}
                        {isError !== null && isError == false && <p>Cette offre expirée dans {dayCount} jours</p> }
                        {isError !== null && isError == true && <p>Cette offre est expirée</p> }
                        <div className={styles.calendar}>
                            <img src={calendar} alt="calendar" />
                        </div>
                    </div> */}
					{product.photos?.length > 1 && (
						<div className={styles.arrows}>
							<div
								className={styles.arrow}
								onClick={addIndexMinus}
							>
								<img
									src={left}
									alt=''
								/>
							</div>
							<div
								className={styles.arrow}
								onClick={addIndexPlus}
							>
								<img
									src={right}
									alt=''
								/>
							</div>
						</div>
					)}
				</div>
				<div className={styles.pricing}>
					<p>{product.title}</p>
					<p>{product.price}€</p>
				</div>
				<div className={styles.description}>
					<p>{product.name}</p>
				</div>
				<div className={styles.detailed}>
					<p>{product.description}</p>
				</div>
				<div className={styles.quantity}>
					<p>Quantité</p>
					<div className={styles.buttons}>
						<button
							className={styles.light}
							onClick={handleMinusCart}
						>
							<img
								src={mince}
								alt=''
							/>
						</button>
						<p>{currQuantity}</p>
						<button
							className={styles.blue}
							onClick={handleAddCart}
						>
							<img
								src={plus}
								alt=''
							/>
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProductBox
