import React from 'react'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { useMutation, useQuery } from '@tanstack/react-query'
import { generatePaymentIntent } from '../../api/product'
import styles from './style.module.scss'

// Modal component
export default function StripeAccountModal({ isOpen, onClose }) {
	const UpdateMultiImage = useMutation({
		mutationFn: generatePaymentIntent,

		onError: (error) => {
			console.log(error)
		},
		onSuccess: (data) => {
			window.location.href = data.link_pay
		},
	})

	function generateConif() {
		UpdateMultiImage.mutate()
		onClose()
	}

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby='modal-title'
			aria-describedby='modal-description'
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Box
				sx={{
					backgroundColor: (theme) => theme.palette.background.paper,
					boxShadow: (theme) => theme.shadows[5],
					padding: (theme) => theme.spacing(2, 4, 3),
					borderRadius: 4,
				}}
                className={styles['add-info-modal-box']}
			>
				<h2 id='modal-title'>Fill Stripe Account Information</h2>
				<p id='modal-description'>
					Please provide the necessary information for your Stripe
					connected account.
				</p>
				<button
					onClick={generateConif}
					variant='contained'
					color='primary'
					sx={{ mt: 2 }}
				>
					Add Information
				</button>
			</Box>
		</Modal>
	)
}
