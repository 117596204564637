import React, { useEffect } from 'react';
import customAxios from '../../axios/custom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { showErrorToast } from '../../layout/toast/toast';
import { getStatusChange } from '../../api/product';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../layout/footer/footer';
import styles from './style.module.scss';
import logo from '../../assets/svg/logo.svg';

const Generate = () =>{

    const {token} = useParams()

    const getId = async () => {
        try {
            const response = await getStatusChange({token});
            console.log(response);
            if (!response) {
                throw new Error('Failed to get user');
            }
            localStorage.setItem('token_verif',token)
            return response;
        } catch (error) {
            // Handle errors appropriately
            console.error('Error fetching user:', error);
            throw error;
        }
    };

    const navigate = useNavigate();
    const {data,isLoading,refetch,isError} =  useQuery({ queryKey: ['get-one'], queryFn: getId, refetchOnWindowFocus: false, enabled: true,retry:false });

    useEffect(()=>{
        if(!isLoading && data){
            
            localStorage.setItem('token',data.token)
           
            setTimeout(() => {
                navigate('/settings')
            }, 3000);

        }
            

    },[data,isLoading])

    return(
        <div className={styles.box_seller}>
            
        <div className={styles.flex}>
            <div className={styles.main_box_container}>
            <div className={styles.logo}>
                <img src={logo} alt="" />
            </div>
                {!isLoading && data && 
                    <div className={styles.box}>
                    <div className={styles.auth_box}>
                        <div className={styles.img_succ}>
                            <i className="fa-solid fa-3x fa-circle-check" ></i>
                        </div>
                        <p  className={styles.green}>Vous êtes bien identifié, vous serez redirigé vers votre profil.</p>
                    </div>
                </div>
                }

                {!isLoading && isError && 
                       <div className={styles.box}>
                    
                       <div className={styles.auth_box}>
                   <div className={styles.img_fialed}>
                       <i className="fa-solid  fa-3x fa-circle-xmark"></i>
                       </div>
                       <p className={styles.red}>Ce lien n'est plus valide, merci de contacter l'emetteur de ce lien.</p>
                       
                   </div>
                   </div>
                }
               
                {isLoading && 
                <div className={styles.box}>
                    
                <div className={styles.auth_box}>
       
                <p className={styles.blue}>Authentification en cours... </p>
                
            </div>
            </div>
                }

            </div>
        </div>
        <Footer></Footer>
    </div>
    )
}

export default Generate;