import React from 'react'
import styles from './style.module.scss'
import TagInput from '../../../layout/tag-input/tag'
import CardShopping from '../../../layout/card-items/card-items'
import { useDispatch, useSelector } from 'react-redux'
import {
	set_add_product,
	set_add_shop,
	set_show_product,
} from '../../../redux/modal'
import {
	ModalChoseProduct,
	ModalShowProduct,
} from '../../../layout/modals/modals'
import { selectProducts } from '../../../redux/product'
import StripeAccountModal from '../../../layout/modals/addInfoModal'

const Product = ({ title, subtitl, bankInfoStatus }) => {
	const dispatch = useDispatch()
	const [addInfoOpen,setAddInfoOpen] = React.useState(false)

	function handleOpenModal() {
		dispatch(set_show_product())
	}
	const products = useSelector(selectProducts)

	function handleShowProd() {
		dispatch(set_add_shop())
	}

	const handleAddAccount = () => {
		console.log({bankInfoStatus})
		setAddInfoOpen(true)
	}

	const handleAddInfoClose = ()=>{
		setAddInfoOpen(false)
	}

	return (
		<div className={styles.order_box}>
			<TagInput
				title={title}
				subTile={subtitl}
				onClick={
					bankInfoStatus !== 'verified'
						? handleAddAccount
						: handleShowProd
				}
				text_btn={'Ajouter un produit'}
			/>
			<ModalShowProduct></ModalShowProduct>
			{bankInfoStatus !== 'verified' && <StripeAccountModal isOpen={addInfoOpen} onClose={handleAddInfoClose}/>}
			<div className={styles.prod_select}>
				{products.map((x, index) => {
					return (
						<CardShopping
							key={x._id}
							title={x.title}
							img={x.photos[0]}
							id={x._id}
							prod={x}
							type='product'
						></CardShopping>
					)
				})}
			</div>
		</div>
	)
}

export default Product
