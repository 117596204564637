import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    isError:null,
    dayCount:null,
    date:null
  },
  reducers: {
    addProductToCart(state, action) {
      const newItem = action.payload;
      const existingItem = state.items.find(item => item._id === newItem._id);
      if (existingItem) {
        existingItem.quantity++;
      } else {        
        state.items.push({ ...newItem, quantity: 1 });
      }
    },
    minusProductToCart(state, action) {
        const itemToDecrement = action.payload;
        const existingItem = state.items.find(item => item._id === itemToDecrement._id);
        if (existingItem) {
            console.log(action.payload.quantity);
          if (existingItem.quantity > 0) {
            existingItem.quantity--;
          } 
        }
      },
      removeProduct(state, action) {
          const itemToDecrement = action.payload;
          state.items = state.items.filter(item => item._id !== itemToDecrement._id);
      
      },
    deleteProductFromCart(state, action) {
      state.items = [];
    },
    errorHandle(state, action){
        state.isError = action.payload
    },
    handleDate(state, action){
        state.date = action.payload
    },
    dayCountHandle(state, action){
        state.dayCount = action.payload
    }
    
  }
});

export const { addProductToCart, deleteProductFromCart,minusProductToCart,errorHandle,dayCountHandle,handleDate,removeProduct } = cartSlice.actions;

export default cartSlice.reducer;
