import React, { useEffect, useState } from 'react'
import {
	CardElement,
	Elements,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { CheckoutForm } from '../../components/checkout/checkout'
import { useGetToken } from '../../utils/functions'
import { useParams } from 'react-router-dom'

const Payment = () => {
	// const stripePromise = loadStripe('pk_test_51OfIxvLxLlBK4ZfzrRZtQqfMJBFLFw6whx5geuNkH3GsKQWaz9yah99nltotIbBm4wDaaWDog1Ri0UW71Zx9cqAJ00cmFMymKJ')
	const stripePromise = loadStripe('pk_live_51OfIxvLxLlBK4Zfzkgwj4MIcBexlcTq2seduEy9F6t8wS7uAiMrgL2LyAxYpESuUgvasSk9bjvv3KrZpXc1WgZH900zwOAyUMY')
	const [elements, setElements] = useState(null)
	const [stripe, setStripe] = useState(null)

	const [options, setOptions] = useState({
		mode: 'payment',
		currency: 'eur',
		amount: 200,
	})

	const { token } = useParams()

	const { data, isLoading, isError } = useGetToken(token)
	useEffect(() => {
		if (data && !isLoading) {
			setOptions({
				...options,
				appearance: {
					/* Update appearance if needed */
				},
			})
		}
	}, [])

	// useEffect(()=>{

	//     console.log(data);
	// },[data])

	// useEffect(()=>{
	//     console.log(options);
	// },[options])

	return (
		<Elements
			stripe={stripePromise}
			options={options}
		>
			<CheckoutForm />
		</Elements>
	)
}

export default Payment
