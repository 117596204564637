import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment/moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export default function DataTable({ rows, setOpenConfirmation }) {
  if (rows.length === 0) {
    return null;
  }
  return (
    <TableContainer component={Paper} sx={{ height: "calc(100vh - 200px)" }}>
      <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>Montant</TableCell>
            <TableCell align=''>Client</TableCell>
            <TableCell align='left'>Date</TableCell>
            {/* <TableCell align='center'>Refunded date</TableCell> */}
            <TableCell align='left'>Statut</TableCell>
            <TableCell align='center'>Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows?.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component='th' scope='row' sx={{ fontWeight: 600 }}>
                {row.currency === "eur" ? "€" : "$"}
                {(row.amount / 100).toFixed(2)}
              </TableCell>
              <TableCell align=''>{row.customer}</TableCell>
              <TableCell align='left'>
                {moment(row.date * 1000).format("MMM D, YYYY, h:mm A")}
              </TableCell>
              {/* <TableCell align='center'>{row.refundedDate}</TableCell> */}
              <TableCell
                sx={{
                  "& p": {
                    padding: "2px 5px",
                    borderRadius: "5px",
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    width: "max-content",
                    ...(row.status === "succeeded" &&
                    row.captured &&
                    !row.refunded
                      ? {
                          backgroundColor: "#d1fab3",
                          color: "#216F07",
                          border: "1px solid #A8F170",
                        }
                      : {
                          backgroundColor: "#f5f5f5",
                          color: "GrayText",
                          border: "1px solid #dadada",
                        }),
                  },
                }}>
                <p>
                  <span>
                    {row.refunded
                      ? "remboursé"
                      : row.captured
                      ? row.status === "successful"
                        ? "Réussi"
                        : row.status
                      : "non capturé"}
                  </span>
                  {row.refunded ? (
                    <ReplayIcon sx={{ fontSize: 12 }} />
                  ) : row.captured ? (
                    <DoneIcon sx={{ fontSize: 12 }} />
                  ) : (
                    <AccessTimeIcon sx={{ fontSize: 12 }} />
                  )}
                </p>
              </TableCell>
              <TableCell align='center'>
                {row.status === "succeeded" && row.captured && !row.refunded ? (
                  <Button
                    size='small'
                    variant='contained'
                    sx={{
                      // ...(default_for_currency ? { ml: 'auto' } : {}),
                      ml: "auto",
                      backgroundColor: "cadetblue",
                      borderRadius: 2.5,
                      "&:hover": {
                        backgroundColor: "#5f9ea0d6",
                      },
                    }}
                    onClick={() => setOpenConfirmation(row)}>
                    Remboursement
                  </Button>
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
