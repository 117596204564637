import React from "react";
import TagBox from "../../../layout/card-sell/card-sell";
import TagText from "../../../layout/tag-sell/tag";
import styles from './style.module.scss';
import { useDesktop } from "../../../utils/useQueryMedia";
import { useQuery } from "@tanstack/react-query";
import { GetTopProd } from "../../../api/product";

const Prod = [{
    title:'Late Check Out',
    price:"80"
},
{
    title:'Late Check Out',
    price:"80"
},
{
    title:'Late Check Out',
    price:"80"
},
{
    title:'Late Check Out',
    price:"80"
},
{
    title:'Late Check Out',
    price:"80"
}
]

const ProdMobile = [{
    title:'Late Check Out',
    price:"80"
},
{
    title:'Late Check Out',
    price:"80"
},
{
    title:'Late Check Out',
    price:"80"
},
{
    title:'Late Check Out',
    price:"80"
},
{
    title:'Late Check Out',
    price:"80"
}
,
{
    title:'Late Check Out',
    price:"80"
}
]

const Seller = ({title,subtitl,img,data:userData}) =>{
    const isDesktop = useDesktop()
    const getId = async () => {
        try {
            const response = await GetTopProd();

            if (!response) {
                throw new Error('Failed to get user');
            }
            return response;
        } catch (error) {
            // Handle errors appropriately
            console.error('Error fetching user:', error);
            throw error;
        }
    };

    const {data,isLoading,isError} = useQuery({ queryKey: ['get-one-order-top'], queryFn: getId, refetchOnWindowFocus: false, enabled: true });
    return(
        <div className={styles.seller_box}>
            <TagText title={title} subTile={subtitl} img={img} userData={userData}/>
            <div className={styles.box}>
                {
                    isDesktop && data && !isLoading && data.map((x,index)=>{
                        return(
                            <TagBox img={x.photos[0]} price={x.price} title={x.title} index={index} key={index}/>
                        )
                    })
                }
                {
                    !isDesktop&& data && !isLoading && data.map((x,index)=>{
                        return(
                            <TagBox img={x.photos[0]} price={x.price} title={x.title} index={index} key={index}/>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Seller;