import React from "react";
import styles from './style.module.scss';

import bonrze from '../../assets/svg/bronze.svg';
import gold from '../../assets/svg/gold.svg';
import silver from '../../assets/svg/silver.svg';



const TagBox = ({title,price,index,img}) => {
    return (
        <>
        
        {index == 0 && 
            <div className={styles.boxCard}>
            <div className={styles.cup+' ' +styles.background_blue} >
                <img src={gold} alt="gold" />
            </div>
            <div className={styles.card_main+' ' +styles.background_blue}>
                <div className={styles.img_text}>
                    <div className={styles.img}>
                        <img src={img} alt="clock" />
                    </div>   
                    <p>{title}</p>
                </div>
                <p>{price}€</p>
            </div>
        </div>
        }
        {index == 1 && 
            <div className={styles.boxCard}>
            <div className={styles.cup+' ' +styles.background_green}>
                <img src={silver} alt="gold" />
            </div>
            <div className={styles.card_main+' ' +styles.background_green}>
                <div className={styles.img_text}>
                    <div className={styles.img}>
                        <img src={img} alt="clock" />
                    </div>   
                    <p>{title}</p>
                </div>
                <p>{price}€</p>
            </div>
        </div>
        }
        {index == 2 && 
            <div className={styles.boxCard}>
            <div className={styles.cup+' ' +styles.background_white}>
                <img src={bonrze} alt="gold" />
            </div>
            <div className={styles.card_main+' ' +styles.background_white}>
                <div className={styles.img_text}>
                    <div className={styles.img}>
                        <img src={img} alt="clock" />
                    </div>   
                    <p>{title}</p>
                </div>
                <p>{price}€</p>
            </div>
        </div>
        }
        {index > 2 && 
            <div className={styles.boxCard}>
            <div className={styles.card_main_empty+" "+ styles.background_white}>
                <div className={styles.img_text}>
                    <div className={styles.img}>
                        <img src={img} alt="clock" />
                    </div>   
                    <p>{title}</p>
                </div>
                <p>{price}€</p>
            </div>
        </div>
        }
        </>
        
    )
}

export default TagBox;