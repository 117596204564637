import React from "react";
import styles from './style.module.scss';
import logo from '../../assets/svg/logo.svg'
const Footer = () =>{

    return(
        <div className={styles.footer}>
            <div className={styles.box}>
                
                <div className={styles.container_footer}>
                    <div className={styles.footer_card}>
                        <p>Propulsé par</p>
                        <img src={logo} alt="logo" />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Footer;