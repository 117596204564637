import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import TagText from "../../../layout/tag-sell/tag";
import { fakerDE as faker } from "@faker-js/faker";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { useOderMonth } from "../../../utils/functions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Revenue Menusel",
    },
  },
};

const Stats = ({ title, subtitle }) => {
  const { data, isLoading } = useOderMonth();
  const [chartData, setChartData] = useState(null);

  const labels = [
    "Jan.",
    "Fév.",
    "Mar.",
    "Avr.",
    "Mai",
    "Juin",
    "Juil.",
    "Août",
    "Sept.",
    "Oct.",
    "Nov.",
    "Déc.",
  ];

  useEffect(() => {
    if (data && !isLoading) {
      const orderCounts = labels.map((label) => {
        const monthYearKey =
          labels.indexOf(label) + 1 + "/" + new Date().getFullYear();
        return data[monthYearKey] || 0;
      });

      setChartData({
        labels: labels,
        datasets: [
          {
            label: "Revenu",
            data: orderCounts,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      });
    }
  }, [data, isLoading]);

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
        text: "",
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
    },
  };

  return (
    <div className={styles.bar_container}>
      <TagText title={title} subTile={subtitle} />
      <div className={styles.bar}>
        {chartData && <Line data={chartData} options={options} />}{" "}
        {/* Render Bar chart when chartData is available */}
      </div>
    </div>
  );
};

export default Stats;
