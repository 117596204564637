import customAxios from '../axios/custom';

export async function Create({ from, data }) {
  // console.log(data);
  const res = await customAxios.post('/api/v1/' + from, data);
  return res.data;
}
export async function Get({ from }) {
  // console.log(data);
  const res = await customAxios.get('/api/v1/' + from);
  return res.data;
}
export async function Update({ from, data }) {
  // console.log(data);
  const res = await customAxios.put('/api/v1/' + from, data);
  return res.data;
}
export async function Delete({ from }) {
  // console.log(data);
  const res = await customAxios.delete('/api/v1/' + from);
  return res.data;
}
