import {
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import React, { useEffect } from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DeleteIcon from '@mui/icons-material/Delete';
import { Delete, Get, Update } from '../../api/external-bank';
import Confirmation from './Confirmation';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';

const ExternalAccounts = ({
  user,
  reset,
  setBankAccounts,
  bankAccounts,
  expanded,
  handleAdd,
  id
}) => {
  const [selectedAccount, setSelectedAccount] = React.useState(null);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleConfirmationClose = () => {
    setOpenConfirmation(false);
    setSelectedAccount(null);
  };
  const handleClick = async (event, account) => {
    setAnchorEl(event.currentTarget);
    setSelectedAccount(account);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getBankAccounts = async () => {
      try {
        setLoading(true);
        const response = await Get({ from: 'external-bank/' + user.back_acc });
        response?.externalAccounts?.data &&
          setBankAccounts(response.externalAccounts.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (user?.back_acc) {
      getBankAccounts();
    }
  }, [user?.back_acc]);

  const handleDelete = async () => {
    try {
      // console.log({ selectedAccount });
      setDeleteLoading(true);
      const response = await Delete({
        from: `external-bank/${user.back_acc}/${selectedAccount.id}`
      });
      setBankAccounts(prev => prev.filter(v => v.id !== selectedAccount.id));
      setSelectedAccount(null);
      handleConfirmationClose();
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const makeDefault = async () => {
    try {
      setAnchorEl(null);
      await Update({
        from: 'external-bank/' + user.back_acc + '/' + selectedAccount.id,
        data: { default_for_currency: true }
      });
      setBankAccounts(prev =>
        prev.map(v => ({
          ...v,
          default_for_currency: v.id === selectedAccount.id
        }))
      );
      setSelectedAccount(null);
    } catch (error) {
      console.log(error);
    }
  };

  if (!bankAccounts.length) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <Typography variant="h6">Comptes bancaires externes</Typography>
        {(!expanded || id) && (
          <Button startIcon={<AddIcon />} onClick={handleAdd}>
            Ajouter
          </Button>
        )}
      </Box>
      {loading && !bankAccounts.length && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && !bankAccounts.length && (
        <Typography variant="body2" textAlign={'center'} py={2}>
          Aucune donnée disponible!
        </Typography>
      )}
      {bankAccounts.map((bankAccount, i) => {
        const { bank_name, routing_number, last4, default_for_currency } =
          bankAccount;

        return (
          <React.Fragment key={bankAccount.id}>
            {!!i && <Divider sx={{ my: 1 }} />}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AccountBalanceIcon
                sx={{ backgroundColor: 'lightgray', p: 1, borderRadius: 2 }}
              />
              <div>
                <Typography variant="body1">{bank_name}</Typography>
                <Typography
                  variant="body2"
                  sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                  {routing_number}{' '}
                  {[1, 2, 3, 4].map((_, i) => (
                    <span
                      key={i}
                      style={{
                        height: '6px',
                        width: '6px',
                        backgroundColor: '#454545',
                        borderRadius: '50%',
                        display: 'inline-block'
                      }}
                    />
                  ))}{' '}
                  {last4}
                </Typography>
              </div>
              {default_for_currency && <Chip label="défaut" />}
              <Button
                variant="contained"
                sx={{
                  // ...(default_for_currency ? { ml: 'auto' } : {}),
                  ml: 'auto',
                  backgroundColor: 'cadetblue',
                  borderRadius: 2.5,
                  '&:hover': {
                    backgroundColor: '#5f9ea0d6'
                  }
                }}
                onClick={() => reset({ ...bankAccount })}>
                Modifier
              </Button>

              <IconButton
                sx={{
                  visibility: !default_for_currency ? 'visible' : 'hidden',
                  display: bankAccounts?.length === 1 ? 'none' : 'inline'
                }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={e => handleClick(e, bankAccount)}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}>
                <MenuItem onClick={makeDefault}>Faire défaut</MenuItem>

                <MenuItem
                  onClick={() => {
                    setOpenConfirmation(true);
                    setAnchorEl(null);
                  }}>
                  {/* <DeleteIcon color={'error'} />  */}
                  Supprimer
                </MenuItem>
              </Menu>
            </Box>
            <Confirmation
              description={`${bank_name} ending ${last4} will be deleted permanently!`}
              handleClose={handleConfirmationClose}
              handleYes={handleDelete}
              open={openConfirmation}
              loading={deleteLoading}
            />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default ExternalAccounts;
