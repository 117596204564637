import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    products:[]
}

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        set_delete_product: (state, action) => {
            state.products = state.products.filter(product => product._id !== action.payload.id);
        },
        set_add_product: (state, action) => {
            state.products.push(action.payload);
        },
        set_get_product: (state, action) => {
            state.products = action.payload;
        },
    },
});

export const { set_delete_product, set_add_product, set_get_product } = productSlice.actions;

export const selectProducts = (state) => state.productReducer.products;

export default productSlice.reducer;