import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import pen from '../../assets/svg/pen.svg'
import corb from '../../assets/svg/corb.svg'
import { useDelete } from '../../utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import { set_delete_shop } from '../../redux/shop'
import {
	set_add_product,
	set_close_modal,
	set_handle_deletes,
	set_modify_product,
	set_modify_shop,
} from '../../redux/modal'
import {
	ConfirmDelete,
	ModalModifyProduct,
	ModalModifyShop,
} from '../modals/modals'
import { useNavigate } from 'react-router-dom'
import { set_delete_product } from '../../redux/product'
const CardShopping = ({ title, id, img, prod, type }) => {
	const [deletePopen, setOpenDelete] = useState(false)
	const deleteMutation = useDelete()
	const dispatch = useDispatch()
	const [currentSelected, setCurrentSelected] = useState(null)
	const [imageError, setImageError] = useState(false)

	const handleImageError = () => {
		setImageError(true)
	}
	function deleteItem() {
		deleteMutation.mutate(
			{ from: type == 'product' ? 'product' : 'shop', data: id },
			{
				onSuccess: (data, error, variables, context) => {
					type !== 'product'
						? dispatch(set_delete_shop({ id: currentSelected }))
						: dispatch(set_delete_product({ id: currentSelected }))

					dispatch(set_close_modal())
				},
			}
		)
	}
	const [open, setOpen] = useState(false)
	function openDelete() {
		setCurrentSelected(id)
		setOpenDelete(true)
		dispatch(set_handle_deletes())
	}
	function handleOpenModals() {
		setOpen(!open)
		if (type == 'shop') {
			dispatch(set_modify_product())
		} else if (type == 'product') {
			dispatch(set_modify_shop())
		}
		setCurrentItem(prod)
	}
	const navigate = useNavigate()
	const [currentItem, setCurrentItem] = useState()
	const openLinkInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}
	return (
		<div className={styles.main_card}>
			{open && type == 'shop'
				? currentItem && (
						<ModalModifyShop
							prod={currentItem}
							closeModal={() => {
								setCurrentItem(undefined)
								setOpen(false)
							}}
						></ModalModifyShop>
				  )
				: currentItem && (
						<ModalModifyProduct
							prod={currentItem}
							closeModal={() => {
								setCurrentItem(undefined)
								setOpen(false)
							}}
						/>
				  )}
			{currentSelected === id && (
				<ConfirmDelete
					open={deletePopen}
					setOpen={setOpenDelete}
					setCurrentSelected={setCurrentSelected}
					onClick={deleteItem}
					title={type == 'shop' ? 'shop' : 'ce produit'}
				/>
			)}
			<div className={styles.splited_box}>
				<div className={styles.desc_items}>
					{type == 'shop' && (
						<i
							className='fa-solid fa-link'
							style={{ color: '#60C1D0', cursor: 'pointer' }}
							onClick={
								(e) =>
									openLinkInNewTab(
										window.location.origin +
											'/shop/' +
											prod?.token
									)
								// navigate('/shop/'+prod?.token)
							}
						></i>
					)}
					<img
						src={
							!imageError
								? img
								: type == 'shop'
								? '/shop-image.jpg'
								: '/product.jpg'
						}
						alt=''
						onError={handleImageError}
					/>
					<p>{title}</p>
				</div>
				<div
					className={styles.pen_box}
					onClick={handleOpenModals}
				>
					<img
						src={pen}
						alt=''
					/>
				</div>
			</div>
			<div
				className={styles.delete}
				onClick={(e) => openDelete()}
			>
				<img
					src={corb}
					alt='corb'
				/>
			</div>
		</div>
	)
}

export default CardShopping
