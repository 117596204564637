
import Shop from './pages/shop/shop';
import SellerPage from './pages/seller/seller';
import SuccessPayment from './pages/payment/success';
import Generate from './pages/main/generate';

import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import NotFoundPage from './pages/notfound/404';
import Payments from './pages/adminDashboard/payments';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NotFoundPage />} />
        <Route path="/settings" element={<PrivateRoute component={SellerPage} />} />
        <Route path="/payments" element={<PrivateRoute component={Payments} />} />
        <Route path="/shop/:token" element={<Shop />} />
        <Route path="/success-payment" element={<SuccessPayment />} />
        <Route path="/home/:token" element={<Generate />} />
        <Route path="/:token*" element={<Generate />} />
      </Routes>
    </BrowserRouter>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/home', { replace: true });
    }
  }, [navigate]);

  return <Component {...rest} />;
}

export default App;
