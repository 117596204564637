import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shops:[]
}

const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        set_delete_shop: (state, action) => {
            console.log(action.payload.id);
            state.shops = state.shops.filter(shop => shop._id !== action.payload.id);
        },
        set_add_shop: (state, action) => {
            state.shops.push(action.payload);
        },
        set_get_shop: (state, action) => {
            state.shops = action.payload;
        },
    },
});

export const { set_delete_shop, set_add_shop, set_get_shop } = shopSlice.actions;

export const selectshops = (state) => state.shopReducer.shops;

export default shopSlice.reducer;