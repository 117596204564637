import React, { useEffect } from "react";
import styles from './style.module.scss';

const SelectCustom = ({img,text,onChange,isChecked,item}) => {

    function handleData(){
        onChange(text,item)
    }
    
    useEffect(()=>{
        console.log(isChecked);
    },[isChecked])

    return (
        <div className={styles.list} onClick={handleData}>
            <div className={styles.flex}>
                <div className={styles.box} >
                    {isChecked && <div className={styles.filled}></div>}
                </div>
                <div className={styles.flex_inside}>
                        <img src={img} alt="" />
                        <p>{text.title}</p>
                </div>
            </div>
        </div>
    )
}

export default SelectCustom;