import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { Get, GetId, Update,Create, Delete, Token, StatusChange, GetOrdersMonth, userLogin } from '../api/product';
import { showErrorToast, showSuccessToast } from '../layout/toast/toast';
import { set_get_product } from '../redux/product';
import { set_get_shop } from '../redux/shop';

export const useGetId = (from,id) => {
    const dispatch = useDispatch();

    const getId = async () => {
        try {
            const response = await GetId(from,id);

            if (!response) {
                throw new Error('Failed to get user');
            }
            return response;
        } catch (error) {
            // Handle errors appropriately
            console.error('Error fetching user:', error);
            throw error;
        }
    };

    return useQuery({ queryKey: ['get-one-sss'], queryFn: getId, refetchOnWindowFocus: false, enabled: true });
};

export const useGetIdLogin = () => {
    const dispatch = useDispatch();
    const token = localStorage.getItem('token_verif')
    const getId = async () => {
        try {
            const response = await userLogin({token});

            if (!response) {
                throw new Error('Failed to get user');
            }
            return response;
        } catch (error) {
            // Handle errors appropriately
            console.error('Error fetching user:', error);
            throw error;
        }
    };

    return useQuery({ queryKey: ['get-one-user'], queryFn: getId, refetchOnWindowFocus: false, enabled: true,retry:false });
};

export const useOderMonth = () => {
    const dispatch = useDispatch();

    const getId = async () => {
        try {
            const response = await GetOrdersMonth();

            if (!response) {
                throw new Error('Failed to get user');
            }
            return response;
        } catch (error) {
            // Handle errors appropriately
            console.error('Error fetching user:', error);
            throw error;
        }
    };

    return useQuery({ queryKey: ['get-one-order-motn'], queryFn: getId, refetchOnWindowFocus: false, enabled: true });
};
export  const StatusChangeToken = () => {

    return useMutation({
    
        mutationFn: StatusChange,
    
        onError: (error) => {
          if (error.response.data.error)
          showErrorToast("Error due server rendering !")
    
        },
        onSuccess: (data) => {
          
          
    
        },
    
    });

}

export const useGetToken = (token) => {
    const dispatch = useDispatch();

    const getId = async () => {
        try {
            const response = await Token(token);
            // console.log(token);

            if (!response) {
                throw new Error('Failed to get user');
            }
            return response;
        } catch (error) {
            // Handle errors appropriately
            console.error('Error fetching user:', error);
            throw error;
        }
    };

    return useQuery({ queryKey: ['get-one-token-shop'], queryFn: getId, refetchOnWindowFocus: false, enabled: true });
};


export const useGetAll = (from,id) => {
    const dispatch = useDispatch();

    const get = async () => {
        try {
            const response = await Get(from);
            if(from == "product")
                dispatch(set_get_product(response))
            else if(from == "shop")
                dispatch(set_get_shop(response))
            if (!response) {
                throw new Error('Failed to get user');
            }
            return response;
        } catch (error) {
            // Handle errors appropriately
            console.error('Error fetching user:', error);
            throw error;
        }
    };

    return useQuery({ queryKey: ['get-all-'+ from], queryFn: get, refetchOnWindowFocus: false, enabled: true });
};
export const useUpdateAll = (prod) =>{
    const {data,isLoading,refetch} = useGetAll(prod)
    return useMutation({
    
        mutationFn: Update,
    
        onError: (error) => {
          if (error.response.data.error)
          showErrorToast("Error due server rendering !")
    
        },
        onSuccess: (data) => {
          showSuccessToast('Modification faite avec succès !')
          refetch()
    
        },
    
    });
}
export const useCreate = (prod) =>{
    const {data,isLoading,refetch} = useGetAll(prod)

return useMutation({

    mutationFn: Create,

    onError: (error) => {
      if (error.response.data.error)
      showErrorToast("Error due server rendering !")

    },
    onSuccess: (data) => {
      showSuccessToast('Création faite avec succès !')
      refetch()
    },
});
}

export const useDelete = () =>{
return useMutation({
    mutationFn: Delete,
    
    onError: (error) => {
        if (error.response.data.error)
        showErrorToast("Error due server rendering !")
},
onSuccess: (data) => {
    showSuccessToast('Suppri faite avec succès !')
   
    }

});

}
