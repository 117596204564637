import React, { useEffect } from "react";
import styles from "./style.module.scss";
import { Helmet } from "react-helmet";
import logo from "../../assets/svg/logo.svg";
import { useGetIdLogin } from "../../utils/functions";
import { Get, Post } from "../../api/transactions";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import DataTable from "./data_table";
import Confirmation from "../../components/AddBankAccount/Confirmation";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { albertEmail } from "../../utils/constants";

const Payments = () => {
  const { data, isLoading, isError, refetch } = useGetIdLogin();
  const [rows, setRows] = React.useState([]);
  const [openConfirmation, setOpenConfirmation] = React.useState(null);
  const [confirmationLoading, setConfirmationLoading] = React.useState(false);
  const [dataLoading, setDataLoading] = React.useState(false);

  const handleConfirmationClose = () => {
    setOpenConfirmation(null);
  };

  const handleRefund = async () => {
    try {
      setConfirmationLoading(true);
      const responseData = await Post({
        from: "create-refund",
        data: openConfirmation,
      });
      responseData.success === 200 &&
        setRows((prev) =>
          prev.map((row) => {
            row.id === openConfirmation.id && (row.refunded = true);
            return row;
          })
        );
      handleConfirmationClose();
    } catch (error) {
      console.log(error);
      toast.error(error.message || "Something went wrong!");
    } finally {
      setConfirmationLoading(false);
    }
  };

  useEffect(() => {
    const getAllTransactions = async () => {
      try {
        setDataLoading(true);
        const respData = await Get({
          from: "get-all-transactions",
          data: { limit: 10 },
        });
        setRows(
          respData.charges.data.map((transaction) => ({
            ...transaction,
            customer:
              transaction.metadata?.customerEmail ||
              transaction.billing_details?.name ||
              transaction.billing_details?.email ||
              "Anonyme",
            date: transaction.created,
            refundedDate: transaction?.refunded_at || "-",
          }))
        );
      } catch (err) {
        console.log(err);
      } finally {
        setDataLoading(false);
      }
    };
    data?.email === albertEmail && getAllTransactions();
  }, []);

  if (data?.email !== albertEmail) {
    return <Navigate to={"/settings"} />;
  }

  return (
    <div className={styles.box_seller}>
      <Helmet>
        <meta name='description' content={`boutique ${data?.username}`} />
        <title>{`Boutique ${data?.username}`}</title>
      </Helmet>

      <div className={styles.flex}>
        <div className={styles.main_box_container}>
          <div className={styles.logo}>
            <img src={logo} alt='' />
          </div>
        </div>
      </div>

      <Container>
        <Typography variant='h4' pb={1}>
          Paiements
        </Typography>
        <DataTable
          rows={rows}
          setOpenConfirmation={setOpenConfirmation}
          dataLoading={dataLoading}
        />
      </Container>
      {dataLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", m: 5 }}>
          <CircularProgress />
        </Box>
      )}
      <Confirmation
        description={`Are you sure you want to refund this transaction?`}
        handleClose={handleConfirmationClose}
        handleYes={handleRefund}
        open={openConfirmation}
        loading={confirmationLoading}
      />
    </div>
  );
};

export default Payments;
